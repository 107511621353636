import { Injectable } from "@angular/core";
import {
  AsyncSubject,
  BehaviorSubject,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject
} from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { Bundle } from "src/app/pages/bundles/model/bundles.model";
import { ProductType } from "../product/product.model";
import { FavouriteApi } from "./favourite.api";
import {
  AddToFavouriteInput,
  AddToFavouritesResponse,
  DeleteFavouriteInput,
  FavouriteType
} from "./favourite.model";

@Injectable({ providedIn: "root" })
export class FavouriteFacade {
  private readonly _allFavouritesSubject: BehaviorSubject<ProductType[] | any> =
    new BehaviorSubject(null);

  private readonly _allBundleFavouritesSubject: BehaviorSubject<
    Bundle[] | any
  > = new BehaviorSubject(null);

  // private readonly _favouriteIdSubject: Subject<string> = new Subject();
  constructor(private readonly favouriteApi: FavouriteApi) {}

  public addFavorite(variables: AddToFavouriteInput): Observable<boolean> {
    let isAddedToFavouriteSuccessfullySubject: Subject<boolean> =
      new Subject<boolean>();
    this.favouriteApi
      .addFavorite(variables)
      .pipe(map((x) => x.data.addFavorite))
      .subscribe(
        (data) => {
          isAddedToFavouriteSuccessfullySubject.next(true);
          this.loadFavourites();
        },
        (err) => isAddedToFavouriteSuccessfullySubject.next(false)
      );
    return isAddedToFavouriteSuccessfullySubject;
  }

  public loadFavourites(): void {
    this.favouriteApi
      .getAllFavourites()
      .pipe(
        tap((x) => {
          this._allFavouritesSubject.next(x.data.favorites.products);
          this._allBundleFavouritesSubject.next(x.data.favorites.bundles);
        }),
        map((y) => y.data.favorites.products)
      )
      .subscribe();
  }

  public checkIfProductFavourited(productId: string): ProductType | undefined {
    const allFavourites: ProductType[] = this._allFavouritesSubject.getValue();
    const isChecked = allFavourites?.find((x) => x._id == productId);
    return isChecked;
  }

  public checkIfBundleFavourited(bundleId: string): Bundle | undefined {
    const allFavourites: Bundle[] = this._allBundleFavouritesSubject.getValue();
    const isChecked = allFavourites?.find((x) => x._id == bundleId);
    console.log(isChecked);
    return isChecked;
  }

  public get favourites$(): Observable<ProductType[]> {
    return this._allFavouritesSubject.asObservable();
  }

  public get bundlesFavourites$(): Observable<Bundle[]> {
    return this._allBundleFavouritesSubject.asObservable();
  }

  public deleteFavourite(variables: AddToFavouriteInput): Observable<boolean> {
    let isRemovedFromFavouriteSuccessfullySubject: Subject<boolean> =
      new Subject<boolean>();
    this.favouriteApi
      .deleteFavourite(variables)
      .pipe(map((x) => x.data.removeFavorite))
      .subscribe(
        (data) => {
          isRemovedFromFavouriteSuccessfullySubject.next(true);
          this.loadFavourites();
        },
        (err) => isRemovedFromFavouriteSuccessfullySubject.next(false)
      );
    return isRemovedFromFavouriteSuccessfullySubject;
  }
}
