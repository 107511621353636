import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../graphql/base-service/base-service.service';
import { QueryFull } from '../../../models/graph-models';
import { FUNC_SETTINGS, RETURNS_SETTINGS } from '../model/footer.graphql';
import { SettingResponse } from '../model/footer.model';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private readonly baseService: BaseService) { }

  getSetting(): Observable<SettingResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SETTINGS;
    input.return = RETURNS_SETTINGS;
    return this.baseService.generalQuery(input);
  }
}
