<app-header
  [allCategories]="allCategories$ | async"
  [popularCategories]="popularCategories$ | async"
  [cartDetailsItemsLength]="getCartDetailsLength$ | async"
  [favourites]="favourites$ | async"
  [bundleFavourites]="bundleFavourites$ | async"
></app-header>
<ng-progress #progressBar></ng-progress>

<div class="theApp-main">
  <router-outlet> </router-outlet>
</div>
<app-footer></app-footer>

<app-mobile-nav></app-mobile-nav>
<app-cart-sidebar *ngIf="CartSidebarService.active"></app-cart-sidebar>
