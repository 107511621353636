<div class="categoryCard">
  <a
    class="categoryCard-url"
    [queryParams]="{
      category: category._id
    }"
    [routerLink]="['/products']"
    title="Bedrooms"
  ></a>
  <div class="categoryCard-header">
    <app-image [src]="category.image" alt="Category name"></app-image>
  </div>
  <h4 class="categoryCard-title mb-0">
    {{ category.name || category.arName }}
  </h4>
</div>
