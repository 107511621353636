import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { Observable } from "rxjs";
import {
  FUNC_ADD_TO_FAVOURITE,
  FUNC_DELETE_FAVOUTRITE,
  FUNC_FAVOURITES,
  INPUT_ADD_TO_FAVOURITE,
  INPUT_DELETE_FAVOUTRITE,
  RETURN_ADD_TO_FAVOURITE,
  RETURN_DELETE_FAVOUTRITE,
  RETURN_FAVOURITES,
  TYPE_ADD_TO_FAVOURITE,
  TYPE_DELETE_FAVOUTRITE
} from "./favourite.graphql";
import {
  AddToFavouriteInput,
  AddToFavouritesResponse,
  DeleteFavouriteInput,
  deleteFavouriteResponse,
  GetFavouritesResponse
} from "./favourite.model";

@Injectable({ providedIn: "root" })
export class FavouriteApi {
  constructor(private readonly baseService: BaseService) {}

  getAllFavourites(): Observable<GetFavouritesResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_FAVOURITES;
    input.return = RETURN_FAVOURITES;
    return this.baseService.generalQuery(input);
  }

  addFavorite(
    variables: AddToFavouriteInput
  ): Observable<AddToFavouritesResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_ADD_TO_FAVOURITE;
    input.variable = INPUT_ADD_TO_FAVOURITE;
    input.type = TYPE_ADD_TO_FAVOURITE;
    input.return = RETURN_ADD_TO_FAVOURITE;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  deleteFavourite(
    variables: AddToFavouriteInput
  ): Observable<deleteFavouriteResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DELETE_FAVOUTRITE;
    input.variable = INPUT_DELETE_FAVOUTRITE;
    input.type = TYPE_DELETE_FAVOUTRITE;
    input.return = RETURN_DELETE_FAVOUTRITE;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }
}
