//#region SignUp
export const FUNC_SIGN_UP = "userSignUp";
export const INPUT_SIGN_UP = "userSignUpDetailsInput";
export const TYPE_SIGN_UP = "BuyerSignUpDetailsInput!";
export const BUYER_ID = `{
  _id
}
`;
//#endregion

//#region SocialLogin
export const FUNC_LOGIN_SOCIAL = "socialSignIn";
export const INPUT_LOGIN_SOCIAL = "socialSignInInput";
export const TYPE_LOGIN_SOCIAL = "SocialSignInInput!";
export const RETURN_LOGIN_SOCIAL = `{
  accessToken
}
`;
//#endregion

//#region INPUTS

//#endregion

//#region  TYPES
//#endregion

//#region Returns

//#endregion

//#region signIn
export const FUNC_SIGN_IN = "signIn";
export const INPUT_SIGN_IN = "authCredentialInput";
export const TYPE_SIGN_IN = "AuthCredentialInput!";
export const RETURN_SIGN_IN = `{
  accessToken
}
`;
//#endregion

//#region Forget password
export const FUNC_FORGET_PASSWORD = "forgetPassword";
export const INPUT_FORGET_PASSWORD = "forgetPasswordInput";
export const TYPE_FORGET_PASSWORD = "ForgetPasswordInput!";
export const RETURN_FORGET_PASSWORD = `{
  message
}
`;
//#endregion

//#region set new password
export const FUNC_SET_NEW_PASSWORD = "resetPassword";
export const INPUT_SET_NEW_PASSWORD = "resetUserPasswordInput";
export const TYPE_SET_NEW_PASSWORD = "ResetUserPasswordInput!";
export const RETURN_SET_NEW_PASSWORD = `{
  message
}
`;
//#endregion

//#region user
export const FUNC_USER = "user";
export const RETURN_USER = `{
  _id
  fName
  lName
  email
  phone
  role
}
`;
//#endregion

export const RETURN_USER_PAYMENT_INFO = `{
  role
  payment{
    _id
    bank
    branch
    country
    name
    accountNo
    iban
    swiftCode
    email
  }
}
`;
//#endregion

//#region designerSignUp
export const FUNC_DESIGNER_SIGN_UP = "designerSignUp";
export const INPUT_DESIGNER_SIGN_UP = "designerSignUpDetailsInput";
export const TYPE_DESIGNER_SIGN_UP = "DesignerSignUpDetailsInput!";
export const RETURN_DESIGNER_SIGN_UP = `{
  _id
name
image
email
phone
role
}
`;
//#endregion
