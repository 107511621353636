import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import {
  INPUT_PAGINATION,
  TYPE_PAGINATION
} from "@shared/graphql/shared.graphql";
import { Observable } from "rxjs";
import {
  Buyer,
  DesignerSignUpDetailsInput,
  DesignerSignUpResponse,
  ForgetPasswordResponse,
  LoginSocialResponse,
  SetNewPasswordResponse,
  SignInResponse,
  SignUpResponse,
  SocialSignInInput,
  UserValidationResponse
} from "../models/auth.models";
import {
  FUNC_SIGN_UP,
  BUYER_ID,
  INPUT_SIGN_UP,
  TYPE_SIGN_UP,
  FUNC_SIGN_IN,
  RETURN_SIGN_IN,
  TYPE_SIGN_IN,
  INPUT_SIGN_IN,
  FUNC_FORGET_PASSWORD,
  INPUT_FORGET_PASSWORD,
  RETURN_FORGET_PASSWORD,
  TYPE_FORGET_PASSWORD,
  FUNC_SET_NEW_PASSWORD,
  RETURN_SET_NEW_PASSWORD,
  TYPE_SET_NEW_PASSWORD,
  INPUT_SET_NEW_PASSWORD,
  RETURN_USER,
  FUNC_USER,
  FUNC_LOGIN_SOCIAL,
  RETURN_LOGIN_SOCIAL,
  TYPE_LOGIN_SOCIAL,
  INPUT_LOGIN_SOCIAL,
  INPUT_DESIGNER_SIGN_UP,
  TYPE_DESIGNER_SIGN_UP,
  RETURN_DESIGNER_SIGN_UP,
  FUNC_DESIGNER_SIGN_UP,
  RETURN_USER_PAYMENT_INFO
} from "../models/auth-graphql";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private readonly baseService: BaseService) {}

  signUp(variables: any): Observable<SignUpResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SIGN_UP;
    input.return = BUYER_ID;
    input.type = TYPE_SIGN_UP;
    input.variable = INPUT_SIGN_UP;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  designerSignUp(
    variables: DesignerSignUpDetailsInput
  ): Observable<DesignerSignUpResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DESIGNER_SIGN_UP;
    input.return = RETURN_DESIGNER_SIGN_UP;
    input.type = TYPE_DESIGNER_SIGN_UP;
    input.variable = INPUT_DESIGNER_SIGN_UP;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  signIn(variables: any): Observable<SignInResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SIGN_IN;
    input.return = RETURN_SIGN_IN;
    input.type = TYPE_SIGN_IN;
    input.variable = INPUT_SIGN_IN;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  forgetPassword(variables: any): Observable<ForgetPasswordResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_FORGET_PASSWORD;
    input.return = RETURN_FORGET_PASSWORD;
    input.type = TYPE_FORGET_PASSWORD;
    input.variable = INPUT_FORGET_PASSWORD;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  setNewPassword(variables: any): Observable<SetNewPasswordResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SET_NEW_PASSWORD;
    input.return = RETURN_SET_NEW_PASSWORD;
    input.type = TYPE_SET_NEW_PASSWORD;
    input.variable = INPUT_SET_NEW_PASSWORD;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  userValidation(): Observable<UserValidationResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_USER;
    input.return = RETURN_USER;
    return this.baseService.generalQuery(input);
  }

  userPaymentInfo(): Observable<UserValidationResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_USER;
    input.return = RETURN_USER_PAYMENT_INFO;
    return this.baseService.generalQuery(input);
  }

  signInSocial(variables: SocialSignInInput): Observable<LoginSocialResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_LOGIN_SOCIAL;
    input.return = RETURN_LOGIN_SOCIAL;
    input.type = TYPE_LOGIN_SOCIAL;
    input.variable = INPUT_LOGIN_SOCIAL;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }
}
