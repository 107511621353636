import { Injectable } from "@angular/core";
import { AuthFacade } from "@core/auth/services/auth.facade";
import { HandleErrorsFacade } from "@core/facades/handle-errors.facade";

import { ImagesType } from "@shared/services/product/product.model";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import {
  AddToCartInput,
  AddToCartResponse,
  CartInterface,
  CartDetails,
  CartReturnedProductInterface,
  GetCartResponse,
  DecrementFromCartInput,
  AddToCartInputInterface,
  CartItemsInterface
} from "../model/cart.models";
import { CartService } from "./cart.service";
import "rxjs/add/observable/fromPromise";
import { ToastrService } from "ngx-toastr";
import { removeBlankValuesFromKeys } from "@shared/utils/utils.methods";

@Injectable({ providedIn: "root" })
export class CartFacade {
  private _cartDetails: ReplaySubject<CartDetails> = new ReplaySubject();

  constructor(
    private readonly cartService: CartService,
    private readonly authFacade: AuthFacade,
    private readonly handleErrorFacade: HandleErrorsFacade,
    private readonly toastrService: ToastrService
  ) {}

  private async createCart(): Promise<{ status: boolean; cartId: string }> {
    return new Promise((resolve) => {
      this.cartService.createCart().subscribe(
        (res) => {
          if (this.authFacade.isUserLoggedIn()) {
            localStorage.setItem("cartId", res.data.createCart._id);
            resolve({ status: true, cartId: res.data.createCart._id });
          } else {
            localStorage.setItem("cartId_Guest", res.data.createCart._id);
            resolve({ status: true, cartId: res.data.createCart._id });
          }
        },
        (err) => {
          this.handleErrorFacade.handleError(err);
          resolve({ status: false, cartId: "" });
        }
      );
    });
  }

  public getUserCart$(): Observable<CartInterface> {
    return this.cartService.getUserCart().pipe(map((x) => x.data.userCart));
  }

  private validateCart(): Promise<{ status: boolean; cartId: string }> {
    return new Promise(async (resolve, reject) => {
      try {
        // if (localStorage.getItem("cartId")) {
        if (this.authFacade.isUserLoggedIn()) {
          const userCart = await this.cartService
            .getUserCart()
            .toPromise()
            .catch(() => null);

          if (userCart) {
            localStorage.setItem("cartId", userCart.data.userCart._id);

            const response = {
              status: true,
              data: await this.checkIfCartIdValid(userCart.data.userCart._id)
            };

            resolve({ status: true, cartId: response.data.data.cart._id });
          } else {
            const response = {
              status: true,
              data: await this.createCart()
            };

            resolve({ status: true, cartId: response.data?.cartId });
          }
        } else if (localStorage.getItem("cartId_Guest")) {
          const response = {
            status: true,
            data: await this.checkIfCartIdValid(
              localStorage.getItem("cartId_Guest")
            )
          };
          resolve({ status: true, cartId: response.data.data.cart._id });
        } else {
          const response = {
            status: true,
            data: await this.createCart()
          };

          resolve({ status: true, cartId: response.data?.cartId });
        }
      } catch (error) {
        console.log(error);
        this.toastrService.clear();
        localStorage.removeItem("cartId");
        localStorage.removeItem("cartId_Guest");
      }
    });
  }

  private checkIfCartIdValid(cartId: any): Promise<GetCartResponse> {
    const variables = {
      getCartInput: { cartId: cartId }
    };
    return this.cartService.getCart(variables).toPromise();
  }

  private getCartDetails$(cartId: string): Observable<CartItemsInterface> {
    const variables = {
      getCartInput: { cartId: cartId.toString() }
    };
    return this.cartService.getCart(variables).pipe(
      map((data) => {
        const cartItems: CartItemsInterface = {
          cartReturnedProducts: data.data.cart.storedProduct,
          cartReturnedBundles: data.data.cart.storedBundles
        };
        return cartItems;
      })
    );
  }

  public async checkIfUserHasCart(): Promise<CartInterface | null | undefined> {
    let res: CartInterface | null | undefined;
    return new Promise((resolve, reject) => {
      this.authFacade.isUserLoggedIn$().subscribe(
        async (data) => {
          if (data) {
            if (this.authFacade.isUserLoggedIn()) {
              res = await this.cartService
                .getUserCart()
                .pipe(
                  map((x) => {
                    return x.data.userCart;
                  })
                )
                .toPromise()
                .catch(() => null);
            }
            resolve(res);
          } else {
            resolve(null);
          }
        },
        (err) => {
          resolve(null);
        }
      );
    });
  }

  public async loadCartDetails(): Promise<void> {
    let cartId;
    const userCart = await this.checkIfUserHasCart();
    if (userCart) {
      cartId = userCart._id;
      localStorage.setItem("cartId", cartId);
      // check if user has a cart before login
      const guestCartId = localStorage.getItem("cartId_Guest");
      if (guestCartId) {
        this.getCartDetails$(guestCartId).subscribe((data) => {
          data.cartReturnedProducts.forEach(async (x) => {
            const addToCartInputInterface: AddToCartInputInterface = {
              qty: x.quantity,
              modelId: x.imageModel._id,
              productId: x._id
            };

            await this.addToCart(addToCartInputInterface);
          });
        });
      }

      localStorage.removeItem("cartId_Guest");
    } else {
      cartId = localStorage.getItem("cartId_Guest");
    }
    // const cartId =
    //   localStorage.getItem("cartId") || localStorage.getItem("cartId_Guest");
    if (!cartId) {
      const cartSummary: CartDetails = {
        cartItems: null,
        cartBundles: null,
        cartSummary: {
          items: 0,
          shipping: 0,
          subtotal: 0,
          totalPriceNumber: 0
        }
      };

      this._cartDetails.next(cartSummary);
      return;
    }
    this.getCartDetails$(cartId as string).subscribe(
      (res) => {
        // console.log(res);

        const total =
          res.cartReturnedProducts?.reduce((x, y) => x + y.subtotal, 0) || 0;

        const totalPriceBundles =
          res.cartReturnedBundles?.reduce((x, y) => x + y.subtotal, 0) || 0;

        const totalItems =
          res.cartReturnedProducts?.reduce((x, y) => x + y.quantity, 0) || 0;
        const totalBundles =
          res.cartReturnedBundles?.reduce((x, y) => x + y.quantity, 0) || 0;
        // console.log(totalItems + totalBundles);
        const cartSummary: CartDetails = {
          cartItems: res.cartReturnedProducts,
          cartBundles: res.cartReturnedBundles,
          cartSummary: {
            items: totalItems + totalBundles,
            shipping: 0,
            subtotal: total + totalPriceBundles,
            totalPriceNumber: total + totalPriceBundles
          }
        };
        this._cartDetails.next(cartSummary);
      },
      (err) => {
        // No such cart error
        localStorage.removeItem("cartId");
        localStorage.removeItem("cartId_Guest");

        console.log(err);
      }
    );
  }

  public async addToCart(
    addToCartInputInterface: AddToCartInputInterface
  ): Promise<AddToCartResponse> {
    return new Promise<AddToCartResponse>(async (resolve, reject) => {
      try {
        const validation = await this.validateCart();
        if (validation.status && validation.cartId) {
          const addToCartInput: AddToCartInput = {
            addToCartInput: {
              cartId: validation.cartId,
              modelId: addToCartInputInterface.modelId,
              productId: addToCartInputInterface.productId,
              bundleId: addToCartInputInterface.bundleId,
              qty: addToCartInputInterface.qty || 1
            }
          };

          const addedToCart = await this.cartService.addToCart(addToCartInput);
          this.loadCartDetails();
          resolve(addedToCart);
        } else {
          reject("error occured");
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  public cartDetails$(): Observable<CartDetails> {
    return this._cartDetails.asObservable();
  }

  public decrementCartDetailFromCart(
    decrementCartDetailInput: DecrementFromCartInput
  ): Observable<CartInterface> {
    const variables: DecrementFromCartInput = decrementCartDetailInput;

    return this.cartService.decrementCartDetailFromCart(variables).pipe(
      map((res) => {
        this.loadCartDetails();
        return res.data.decrementFromCart;
      })
    );
  }

  public deleteCartDetailFromCart(
    decrementCartDetailInput: DecrementFromCartInput
  ): Observable<CartInterface> {
    const variables: DecrementFromCartInput = decrementCartDetailInput;

    return this.cartService.deleteCartDetailFromCart(variables).pipe(
      map((res) => {
        this.loadCartDetails();
        return res.data.deleteFromCart;
      })
    );
  }
}
