<div class="modal d-block w-100" style="z-index: 9999999">
  <div class="modal-dialog modal-fullscreen w-100">
    <div class="modal-content w-100 h-100">
      <div class="d-flex justify-content-end">
        <button
          class="close-btn m-5"
          data-dismiss="modal"
          (click)="closeDialiog()"
          aria-label="Close"
        >
          <svg-icon
            style="color: #ffb900; font-size: 4rem"
            src="assets/icons/close.svg"
            class="fs-md"
          ></svg-icon>
        </button>
      </div>

      <div
        class="container col-sm-9 col-md-9 col-lg-6 col-xl-4 h-100"
        (clickOutside)="closeDialiog($event)"
      >
        <div class="modal-body h-100">
          <ng-container *ngTemplateOutlet="tmpl"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div>hii</div> -->
<!-- <div class="modal show d-flex">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modal title</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary">Save changes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="modal-backdrop fade show" *ngIf="show" (click)="close()"></div> -->
<!-- <div class="modal-dialog modal-fullscreen" style="border: 2px red dashed">
  <div class="modal-header bg-info w-100">
    <h5 class="modal-title"><span class="fa fa-cog"></span> Settings</h5>
    <button type="button" class="close" aria-label="Dismiss">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="tmpl"></ng-container>
  </div>
</div> -->
<!-- <div
  class="modal-dialog modal-fullscreen"
  style="height: 100vh; overflow: hidden"
>
  <div class="modal-content container">
    <div class="modal-header">
      <h5 class="modal-title"></h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body w-100 p-0">
      <ng-container *ngTemplateOutlet="tmpl"></ng-container>
    </div>
  </div>
  <div class="modal-footer">footer</div>
</div> -->
<!-- <div class="modal-container">
  <ng-container *ngTemplateOutlet="tmpl"></ng-container>
</div> -->
