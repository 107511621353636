import { Component, Input, OnInit } from "@angular/core";
import { Post } from "src/app/pages/blog/model/blog.model";

@Component({
  selector: "post-card",
  templateUrl: "./post-card.component.html",
  styleUrls: ["./post-card.component.scss"]
})
export class PostCardComponent implements OnInit {
  @Input() post!: Post;
  @Input() imgSrc!: string;
  constructor() {}

  ngOnInit(): void {}
}
