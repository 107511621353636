import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationGuard } from "@core/auth/guards/authentication.guard";
import { NotFoundComponent } from "./pages/not-found/not-found.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule)
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule)
  },
  {
    path: "about",
    loadChildren: () =>
      import("./pages/about/about.module").then((m) => m.AboutModule)
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./pages/faq/faq.module").then((m) => m.FaqModule)
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyModule)
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsModule)
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactModule)
  },
  {
    path: "products",
    loadChildren: () =>
      import("./pages/product-list/product-list.module").then(
        (m) => m.CategoryModule
      )
  },
  {
    path: "deals",
    loadChildren: () =>
      import("./pages/deals/deals.module").then((m) => m.DealsModule)
  },
  {
    path: "product",
    loadChildren: () =>
      import("./pages/product-details/product.module").then(
        (m) => m.ProductModule
      )
  },
  {
    path: "compare",
    loadChildren: () =>
      import("./pages/compare/compare.module").then((m) => m.CompareModule)
  },
  {
    path: "designers",
    loadChildren: () =>
      import("./pages/designers/designers.module").then(
        (m) => m.DesignersModule
      )
  },
  {
    path: "how",
    loadChildren: () =>
      import("./pages/how/how.module").then((m) => m.HowModule)
  },
  {
    path: "custom-design",
    loadChildren: () =>
      import("./pages/custom-design/custom-design.module").then(
        (m) => m.CustomDesignModule
      )
  },
  {
    path: "designers-area",
    loadChildren: () =>
      import("./pages/designers-area/designers-area.module").then(
        (m) => m.DesignersAreaModule
      )
  },
  {
    path: "blog",
    loadChildren: () =>
      import("./pages/blog/blog.module").then((m) => m.BlogModule)
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsModule)
  },
  {
    path: "careers",
    loadChildren: () =>
      import("./pages/careers/careers.module").then((m) => m.CareersModule)
  },
  {
    path: "collections",
    loadChildren: () =>
      import("./pages/collections/collections.module").then(
        (m) => m.CollectionsModule
      )
  },
  {
    path: "wishlist",
    loadChildren: () =>
      import("./pages/wishlist/wishlist.module").then((m) => m.WishlistModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: "instructions",
    loadChildren:() =>
      import("./pages/instructions/instructions.module").then((m) => m.InstructionsModule)
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./pages/checkout/checkout.module").then((m) => m.CheckoutModule)
  },
  {
    path: "account",
    loadChildren: () =>
      import("./pages/account/account.module").then((m) => m.AccountModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: "gift",
    loadChildren: () =>
      import("./pages/gift/gift.module").then((m) => m.GiftModule)
  },
  {
    path: "demo",
    loadChildren: () =>
      import("./pages/demo/demo.module").then((m) => m.DemoModule)
  },
  {
    path: "return-policy",
    loadChildren: () =>
      import("./pages/return-policy/return-policy.module").then(
        (m) => m.ReturnPolicyModule
      )
  },
  {
    path: "sitemap",
    loadChildren: () =>
      import("./pages/sitemap/sitemap.module").then((m) => m.SitemapModule)
  },
  {
    path: "all-categories",
    loadChildren: () =>
      import("./pages/all-categorises/all-categorises.module").then(
        (m) => m.AllCategorisesModule
      )
  },
  { path: "404", component: NotFoundComponent },
  {
    path: "search",
    loadChildren: () =>
      import("./pages/search-list/search-list.module").then(
        (m) => m.SearchListModule
      )
  },
  {
    path: "bundles",
    loadChildren: () =>
      import("./pages/bundles/bundles.module").then((m) => m.BundlesModule)
  },
  {
    path: "advertisments",
    loadChildren: () =>
      import("./pages/advertisments/advertisments.module").then(
        (m) => m.AdvertismentsModule
      )
  },
  { path: "**", redirectTo: "404" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
