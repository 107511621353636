import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { Observable } from "rxjs";
import {
  FUNC_CREATE_ADDRESS,
  FUNC_DELETE_ADDRESS,
  FUNC_GET_CITIES,
  FUNC_GET_COUNTRIES,
  FUNC_GET_USER_ADDRESSES,
  FUNC_UPDATE_ADDRESS,
  INPUT_CREATE_ADDRESS,
  INPUT_DELETE_ADDRESS,
  INPUT_UPDATE_ADDRESS,
  RETURN_CREATE_ADDRESS,
  RETURN_DELETE_ADDRESS,
  RETURN_GET_CITIES,
  RETURN_GET_COUNTRIES,
  RETURN_UPDATE_ADDRESS,
  RETURN_USER_ADDRESSES,
  TYPE_CREATE_ADDRESS,
  TYPE_DELETE_ADDRESS,
  TYPE_UPDATE_ADDRESS
} from "./address.graphql";
import {
  AddAddressResponse,
  CreateAddressInput,
  DeleteAddressInput,
  DeleteAddressResponse,
  GetCitiesResponse,
  GetUserAddressesResponse,
  UpdateAddressInput,
  UpdateAddressResponse
} from "./address.model";

@Injectable({ providedIn: "root" })
export class AddressService {
  constructor(private readonly baseService: BaseService) {}
  getAdresses(): Observable<GetUserAddressesResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_USER_ADDRESSES;
    input.return = RETURN_USER_ADDRESSES;
    return this.baseService.generalQuery(input);
  }

  // getCoutries(): Observable<GetContriesResponse> {
  //   const input = {} as QueryFull;
  //   input.func = FUNC_GET_COUNTRIES;
  //   input.return = RETURN_GET_COUNTRIES;
  //   return this.baseService.generalQuery(input);
  // }

  getCities(): Observable<GetCitiesResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_CITIES;
    input.return = RETURN_GET_CITIES;
    return this.baseService.generalQuery(input);
  }

  addAdress(
    createAddressInput: CreateAddressInput
  ): Observable<AddAddressResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_CREATE_ADDRESS;
    input.variable = INPUT_CREATE_ADDRESS;
    input.type = TYPE_CREATE_ADDRESS;
    input.return = RETURN_CREATE_ADDRESS;
    input.variables = createAddressInput;
    return this.baseService.generalMutationFull(input);
  }

  deleteAddress(
    variables: DeleteAddressInput
  ): Observable<DeleteAddressResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DELETE_ADDRESS;
    input.variable = INPUT_DELETE_ADDRESS;
    input.type = TYPE_DELETE_ADDRESS;
    input.return = RETURN_DELETE_ADDRESS;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  updateAddress(
    variables: UpdateAddressInput
  ): Observable<UpdateAddressResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_UPDATE_ADDRESS;
    input.variable = INPUT_UPDATE_ADDRESS;
    input.type = TYPE_UPDATE_ADDRESS;
    input.return = RETURN_UPDATE_ADDRESS;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }
}
