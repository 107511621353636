import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  AddToCartInputInterface,
  CartDetails,
  CartReturnedProductInterface,
  DecrementFromCartInput
} from "@core/cart/model/cart.models";
import { CartFacade } from "@core/cart/services/cart.facade";
import { CartSidebarService } from "@core/services/cart-sidebar/cart-sidebar.service";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Bundle } from "src/app/pages/bundles/model/bundles.model";

@Component({
  selector: "app-cart-sidebar",
  templateUrl: "./cart-sidebar.component.html",
  styleUrls: ["./cart-sidebar.component.scss"]
})
export class CartSidebarComponent implements OnInit, OnDestroy {
  cartDetails$!: Observable<CartDetails>;
  loadingQtyIndex!: number | undefined;
  cartDetails!: CartDetails;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public CartSidebarService: CartSidebarService,
    private readonly cartFacade: CartFacade,
    private cdk: ChangeDetectorRef
  ) {}

  toggleCartSidebar() {
    this.CartSidebarService.Toggle();
  }

  ngOnInit(): void {
    this.cartDetails$ = this.cartFacade.cartDetails$();
    // this.cartDetails$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
    //   this.cartDetails = data;
    //   console.log(data);
    //   // this.cdk.detectChanges();
    // });
  }

  async addToCart(imgModel: CartReturnedProductInterface): Promise<void> {
    try {
      // Here will decide type of addedCart ( bundle or product )

      const addToCartInputInterface: AddToCartInputInterface = {
        qty: 1,
        modelId: imgModel.imageModel._id,
        productId: imgModel.product._id
      };
      const added = await this.cartFacade.addToCart(addToCartInputInterface);
      if (added) {
        const map = added.data.addToCart.storedProduct.find(
          (x) => x.image == imgModel.image
        );
        imgModel.quantity = map?.quantity as number;
      }
    } catch (error) {
      // this.toastr.error(
      //   error?.message || "Error occured while adding the product"
      // );
    }
  }

  async addBundleToCart(bundleId: string): Promise<void> {
    const addToCartInputInterface: AddToCartInputInterface = {
      qty: 1,
      bundleId
    };
    const added = await this.cartFacade.addToCart(addToCartInputInterface);
  }

  removeCartItemDetails(cartProduct: CartReturnedProductInterface): void {
    const cartId =
      localStorage.getItem("cartId") || localStorage.getItem("cartId_Guest");

    const decrement: DecrementFromCartInput = {
      decrementFromCartInput: {
        cartId: cartId as string,
        modelId: cartProduct.imageModel._id,
        productId: cartProduct.product._id
      }
    };

    this.cartFacade.decrementCartDetailFromCart(decrement).subscribe(
      (data) => {
        const imgAfterUpdated = data.storedProduct.find(
          (x) => x.image == cartProduct.image
        );

        if ((imgAfterUpdated?.quantity as number) >= 1) {
          cartProduct.quantity = imgAfterUpdated?.quantity as number;
        }
      },
      (err) => {
        console.log(err);
        // this.toastr.error(
        //   err?.message || "Error occured while adding the product"
        // );
      }
    );
  }

  decrementBundle(bundleId: string) {
    const cartId =
      localStorage.getItem("cartId") || localStorage.getItem("cartId_Guest");
    const decrement: DecrementFromCartInput = {
      decrementFromCartInput: {
        cartId: cartId as string,
        bundleId
      }
    };
    this.cartFacade
      .decrementCartDetailFromCart(decrement)
      .subscribe((data) => console.log(data));
  }

  calculateStuff(somevalue1: string, somevalue2: string) {
    return Number(somevalue1) + Number(somevalue2);
  }
  ngOnDestroy() {
    console.log("destroyed");
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
