import { Component, Input, OnInit } from "@angular/core";
import { CommonFacade } from "@core/facades/common.facade";
import { CartSidebarService } from "@core/services/cart-sidebar/cart-sidebar.service";
import { Category, SubCategory } from "@shared/models/Shared.interfaces";
import { Global } from "@shared/utils/global-variables";
import { Observable } from "rxjs";
import { CartFacade } from "@core/cart/services/cart.facade";
import { CartDetails } from "@core/cart/model/cart.models";
import { ProductType } from "@shared/services/product/product.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import {
  ProductSearchResultType,
  SearchFilterType,
  SearchProductInput
} from "@shared/services/search/search.model";
import { SearchFacade } from "@shared/services/search/search.facade";
import { Options } from "@angular-slider/ngx-slider";
import { ModalService } from "@shared/services/modal/modal.service";
import { Bundle } from "src/app/pages/bundles/model/bundles.model";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
  // host: {
  //   "(document:click)": "onClick($event)"
  // }
})
export class HeaderComponent implements OnInit {
  activeMenu = false;
  activeCategory!: Category | null;
  activeSubCategory!: SubCategory | null;
  activeSubCategories!: SubCategory[];
  cartDetails$!: Observable<CartDetails>;
  @Input() favourites!: ProductType[] | null;
  @Input() bundleFavourites!: Bundle[] | null;

  searchForm!: FormGroup;
  searchProducts!: ProductSearchResultType[];
  searchFilters!: SearchFilterType;
  letPriceRangeShow: boolean = true;
  selectedCategoriesFilterSearch: boolean[] = [];
  showOverlay: boolean = false;
  noResultsOnPriceRange: boolean = true;

  twoRandomsSubCategories!:
    | { subCatId: string; catId: string; img: string; subCatName: string }[]
    | null; // show when user hovered on category

  @Input("allCategories") allCategories!: Category[] | null;
  @Input("popularCategories") popularCategories!: Category[] | null;

  @Input() cartDetailsItemsLength: number | null = 0;
  PriceValue = 0;
  highValue!: number;
  options: Options = {
    floor: 0,
    ceil: 10000
  };
  searchInput: SearchProductInput = {
    searchProductInput: {
      page: 0,
      keyword: "",
      arCategories: null,
      categories: null,
      designers: null,
      maxPrice: null,
      minPrice: null
    }
  };

  constructor(
    public CartSidebarService: CartSidebarService,
    private readonly commonFacade: CommonFacade,
    private readonly cartFacade: CartFacade,
    private readonly formBuilder: FormBuilder,
    private readonly searchFacade: SearchFacade,
    private modalService: ModalService
  ) {
    // this.cartFacade.setCartDetails();
    this.cartDetails$ = this.cartFacade.cartDetails$();
    this.searchForm = this.formBuilder.group({
      searchKeyword: ["", [Validators.required]]
    });
  }
  changePrice(e: any): void {
    const minValue = e.value;
    const maxValue = e.highValue;
    this.searchInput.searchProductInput.minPrice = minValue;
    this.searchInput.searchProductInput.maxPrice = maxValue;
    this.search(this.searchInput, false);
  }

  categorySearchFilter(category: string, index: number): void {
    this.searchInput.searchProductInput.minPrice = null;
    this.searchInput.searchProductInput.maxPrice = null;

    if (localStorage.getItem("lang") == "en") {
      this.categorySearchFilterManipulation(
        this.searchInput.searchProductInput.categories,
        category,
        index
      );
    } else {
      this.categorySearchFilterManipulation(
        this.searchInput.searchProductInput.arCategories,
        category,
        index
      );
    }

    //
    this.search(this.searchInput, true);

    // this.searchInput.searchProductInput.categories = [category, ...this.searchInput.searchProductInput.categories as []];
  }
  categorySearchFilterManipulation(
    val: string[] | null | undefined,
    cat: string,
    index: number
  ) {
    if (val) {
      const indexOfCategoryIfSelected = val.indexOf(cat);

      if (indexOfCategoryIfSelected > -1) {
        val.splice(indexOfCategoryIfSelected, 1);
        this.selectedCategoriesFilterSearch[index] = false;

        if (val.length == 0) {
          val = null;
        }
      } else {
        this.selectedCategoriesFilterSearch[index] = true;

        val.push(cat);
      }
    } else {
      this.selectedCategoriesFilterSearch[index] = true;

      val = [cat];
    }
    if (localStorage.getItem("lang") == "en") {
      this.searchInput.searchProductInput.categories = val;
    } else {
      // this.searchInput.searchProductInput.categories = val;
      this.searchInput.searchProductInput.arCategories = val;
    }
  }

  ngOnInit() {
    this.searchForm.controls.searchKeyword.valueChanges.subscribe((value) => {
      if (value && value.length > 2) {
        this.selectedCategoriesFilterSearch = [];

        this.searchInput.searchProductInput.keyword = value;
        this.searchInput.searchProductInput.minPrice = null;
        this.searchInput.searchProductInput.maxPrice = null;
        this.searchInput.searchProductInput.categories = null;

        this.search(this.searchInput);
      } else {
        this.searchProducts = [];
      }
    });
  }
  search(searchInput: SearchProductInput, changePrice: boolean = true) {
    this.searchFacade
      .search(searchInput)
      .pipe(take(1))
      .subscribe((data) => {
        if (data.results.length > 0) {
          this.noResultsOnPriceRange = true;
          this.searchFilters = data.filter;

          this.searchProducts = data.results;

          if (changePrice) {
            const maxPrice = Math.max(...data.results.map((o) => o.price), 0);
            const minPrice = data?.results?.reduce((prev, curr) =>
              prev.price < curr.price ? prev : curr
            );
            // if (minPrice.price != maxPrice) {
            this.highValue = maxPrice;
            this.options = {
              floor: minPrice.price,
              ceil: maxPrice,
              hideLimitLabels: true
            };
            this.PriceValue = minPrice.price;

            // } else {
            //   this.options = {
            //     floor: minPrice.price,
            //     ceil: maxPrice,
            //     hideLimitLabels: true
            //   };
            // }

            console.log(
              "priceValue => ",
              this.PriceValue,
              "highValue => ",
              this.highValue,
              this.options
            );
          }
        } else {
          if (changePrice) {
            this.searchProducts = [];
          } else {
            this.noResultsOnPriceRange = false;
          }
        }
      });
  }

  clearSearchProducts() {
    this.searchForm.patchValue({ searchKeyword: "" });
    this.searchProducts = [];
  }

  categoryHovered(e: any, category: Category) {
    e.target.classList.add("is-active");
    this.showOverlay = true;

    this.activeMenu = true;
    this.activeCategory = category;
    this.activeSubCategory = null;
    this.twoRandomsSubCategories = this.getTwoRandomSubCategories(category);
    this.activeSubCategories = category.subCategories;
  }
  private getTwoRandomSubCategories(
    category: Category
  ): { subCatId: string; catId: string; img: string; subCatName: string }[] {
    const results = category.subCategories
      .filter((x) => x.popular === true)
      .sort(function () {
        return 0.5 - Math.random();
      }) // Shuffle array
      .slice(0, 2)
      .map((x) => {
        return {
          img: x.image,
          subCatId: x._id,
          catId: category._id,
          subCatName: x.name || x.arName
        };
      }); // Get first 2 items
    return results;
  }
  allCategoriesHovered(e: any) {
    e.target.classList.add("is-active");
    this.showOverlay = true;
    this.activeMenu = true;
    if (this.allCategories) {
      this.activeCategory = this.allCategories[0];
    }
  }
  navLeave(e: any) {
    e.target.classList.remove("is-active");
    this.showOverlay = false;

    this.activeMenu = false;
    this.activeCategory = null;
    this.activeSubCategory = null;
  }

  subCategoryNavLeave(e: any) {
    this.activeSubCategory = null;
  }

  navLeaveSubCategory(e: any) {
    e.target.classList.remove("is-active");
  }

  changeCategory(category: Category) {
    this.activeCategory = category;
    console.log(category);
  }

  changeLang(lang: string) {
    this.commonFacade.removeItem("lang");
    localStorage.setItem("lang", lang);
    this.commonFacade.refreshPage();
  }

  public get currentLang(): string {
    return Global.lang;
  }

  openSearchDialog(template: any): void {
    this.modalService.displaySearchModal(template);
  }
  closeSearchDialog() {
    this.modalService.closeDialog();
    this.searchProducts = [];
    this.searchForm.reset();
  }
}
