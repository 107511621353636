import { Component, Inject, ViewChild } from "@angular/core";
import { CartSidebarService } from "@core/services/cart-sidebar/cart-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { CategoryFacade } from "@shared/services/category/category.facade";
import { Observable, of, Subject } from "rxjs";
import { Category } from "@shared/models/Shared.interfaces";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";

import { AuthFacade } from "@core/auth/services/auth.facade";
import { CartFacade } from "@core/cart/services/cart.facade";
import { CommonFacade } from "@core/facades/common.facade";
import { Global } from "@shared/utils/global-variables";
import { FavouriteFacade } from "@shared/services/favourite/favourite.facade";
import { ProductType } from "@shared/services/product/product.model";
import { HandleErrorsFacade } from "@core/facades/handle-errors.facade";
import { catchError } from "rxjs/operators";
import { Bundle } from "./pages/bundles/model/bundles.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  allCategories$!: Observable<Category[]>;
  popularCategories$!: Observable<Category[]>;

  getCartDetailsLength$!: Observable<number>;
  favourites$!: Observable<ProductType[]>;
  bundleFavourites$!: Observable<Bundle[]>;

  private _testSubject: Subject<number> = new Subject();
  te = 1;
  title = "kemitt-website-v2";
  @ViewChild("progressBar") progressBar: any;

  constructor(
    public CartSidebarService: CartSidebarService,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly CategoryFacade: CategoryFacade,
    private router: Router,
    private readonly authFacade: AuthFacade,
    private readonly cartFacade: CartFacade,
    private readonly favouriteFacade: FavouriteFacade
  ) {
    let auth = true;

    // if (!localStorage.getItem("beta-user")) {
    //   const password = window.prompt("write password");
    //   if (password != "kk-v-2") {
    //     window.location.href = "https://kemitt.com/ar-eg/";
    //     auth = false;
    //     return;
    //   }
    //   localStorage.setItem("beta-user", "admin");
    //   auth = true;
    // }
    if (auth) {
      this.initializeApp();
      this.authFacade.userValidation();

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.progressBar.start();
        } else if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.progressBar.complete();
          }, 650);
        }
      });

      //#region Home Categories
      this.CategoryFacade.loadMainApis();
      this.allCategories$ = this.CategoryFacade.getAllCategories$();

      this.popularCategories$ = this.CategoryFacade.getPopularCategories$({
        paginationOptions: { page: 1, limit: 9 }
      });
      //#endregion

      //#region load cart details as a user or a guest
      let cartId;

      this.authFacade.isUserLoggedIn$().subscribe((data) => {
        if (localStorage.getItem("AUTH_TOKEN")) {
          this.cartFacade
            .getUserCart$()
            .pipe(catchError((err) => of(null)))
            .subscribe(
              (data) => {
                if (data) {
                  cartId = data._id;
                  if (cartId) {
                    localStorage.removeItem("cartId_Guest");
                    localStorage.setItem("cartId", cartId);
                    this.cartFacade.loadCartDetails();
                  } else {
                    this.getCartDetailsLength$ = of(0);
                  }
                } else {
                  if (localStorage.getItem("cartId_Guest")) {
                    localStorage.setItem(
                      "cartId",
                      localStorage.getItem("cartId_Guest") as string
                    );
                    console.log(localStorage.getItem("cartId_Guest"));
                    localStorage.removeItem("cartId_Guest");
                  }
                }
              },
              (err) => {
                console.log(err);
              }
            );
        } else {
          cartId = localStorage.getItem("cartId_Guest");
          if (cartId) {
            this.cartFacade.loadCartDetails();
          } else {
            this.getCartDetailsLength$ = of(0);
          }
        }
      });

      //#endregion

      //#region load a Favourites as a user only
      this.authFacade.isUserLoggedIn$().subscribe((data) => {
        if (data) {
          this.favouriteFacade.loadFavourites();
          this.favourites$ = this.favouriteFacade.favourites$;
          this.bundleFavourites$ = this.favouriteFacade.bundlesFavourites$;
        }
      });
      //#endregion
    }
  }

  private initializeApp() {
    if (
      localStorage.getItem("lang") &&
      localStorage.getItem("lang")?.indexOf("ar") != -1
    ) {
      Global.lang = "ar";
      localStorage.setItem("lang", "ar");
      this.translateService.setDefaultLang("ar");
      this.document.dir = "rtl";
    } else {
      Global.lang = "en";
      localStorage.setItem("lang", "en");
      this.translateService.setDefaultLang("en");
      this.document.dir = "ltr";
    }
  }

  private changeProgressColor() {
    this.progressBar.setConfig({ color: "green" });
  }
  test() {
    let cartId;
    if (localStorage.getItem("AUTH_TOKEN")) {
      this.cartFacade
        .getUserCart$()
        .pipe(catchError((err) => of(null)))
        .subscribe(
          (data) => {
            console.log(data);

            if (data) {
              cartId = data._id;
              if (cartId) {
                localStorage.setItem("cartId", cartId);
                this.cartFacade.loadCartDetails();
              } else {
                this.getCartDetailsLength$ = of(0);
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      cartId = localStorage.getItem("cartId_Guest");
      if (cartId) {
        this.cartFacade.loadCartDetails();
      } else {
        this.getCartDetailsLength$ = of(0);
      }
    }
  }
}
