import { Component, Input, OnInit } from "@angular/core";
import { Buyer } from "@core/auth/models/auth.models";

@Component({
  selector: "designer-card",
  templateUrl: "./designer-card.component.html",
  styleUrls: ["./designer-card.component.scss"]
})
export class DesignerCardComponent implements OnInit {
  @Input() designer!: Buyer;
  @Input() productCount!: number;



  constructor() {}

  ngOnInit(): void {}
}
