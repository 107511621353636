import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root"
})
export class HandleErrorsFacade {
  static injector: Injector;
  static router: Router;
  constructor(
    private readonly toastrService: ToastrService,
    private _injector: Injector,
    private readonly _router: Router
  ) {
    HandleErrorsFacade.injector = _injector;
    HandleErrorsFacade.router = _router;
  }

  private static get toastrServiceE(): ToastrService {
    return HandleErrorsFacade.injector?.get(ToastrService);
  }

  handleError(error: any) {
    // this.toastrService.error(error?.graphQLErrors[0].message);
    console.log(error);
  }

  static serverError(errMsg: string) {
    this.toastrServiceE?.error(errMsg);
    setTimeout(() => {
      HandleErrorsFacade.router.navigate(["../"]);
    }, 1000);
  }

  static validationError(errMsg: string) {
    console.log(errMsg);
    // this.toastrServiceE?.error(errMsg);

    if (errMsg.toLowerCase() !== "no such cart") {
      this.toastrServiceE?.error(errMsg);
    }
  }
}
