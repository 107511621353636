import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";

import { QueryFull } from "@core/models/graph-models";
import {
  INPUT_PAGINATION,
  TYPE_PAGINATION
} from "@shared/graphql/shared.graphql";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import {
  AllCategoriesResponse,
  PopularCategoriesResponse,
  SubCategoryByCategoryIdResponse
} from "@shared/models/Shared.interfaces";
import { Observable } from "rxjs";
import { GetSubcategoriesByCategoryIdInput } from "@shared/services/product-list/product-list.model";
import {
  FUNC_ALL_CATEGORIES,
  FUNC_POPULAR_CATS,
  FUNC_SUBCATEGORY_BY_CATEGORYID,
  INPUT_SUBCATEGORY_BY_CATEGORYID,
  RETURNS_ALL_CATS,
  RETURNS_POPULAR_CATS,
  RETURN_SUBCATEGORY_BY_CATEGORYID,
  TYPE_SUBCATEGORY_BY_CATEGORYID
} from "./category.graphql";

@Injectable({
  providedIn: "root"
})
export class CategoryApiService {
  constructor(private readonly baseService: BaseService) {}

  getAllCategories(variables: any): Observable<AllCategoriesResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_ALL_CATEGORIES;
    input.return = RETURNS_ALL_CATS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }

  getPopularCategories(
    variables: PaginationOptions
  ): Observable<PopularCategoriesResponse> {
    const input = {} as QueryFull;
    input.variables = variables;
    input.func = FUNC_POPULAR_CATS;
    input.return = RETURNS_POPULAR_CATS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    return this.baseService.generalQueryFull(input);
  }

  getSubCategoryByCategoryId(
    variables: GetSubcategoriesByCategoryIdInput
  ): Observable<SubCategoryByCategoryIdResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SUBCATEGORY_BY_CATEGORYID;
    input.return = RETURN_SUBCATEGORY_BY_CATEGORYID;
    input.variable = INPUT_SUBCATEGORY_BY_CATEGORYID;
    input.type = TYPE_SUBCATEGORY_BY_CATEGORYID;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }
}
