import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { FUNC_BUYER, RETURN_BUYER } from "@shared/graphql/shared.graphql";
import { GetBuyerResponse } from "@shared/models/Shared.interfaces";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class BuyerService {
  constructor(private readonly baseService: BaseService) {}

  getBuyerInfo(): Observable<GetBuyerResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_BUYER;
    input.return = RETURN_BUYER;
    return this.baseService.generalQuery(input);
  }
}
