import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class Global {
  constructor() {}

  // lang
  private static _lang: string;
  public static set lang(v: string) {
    this._lang = v;
  }
  public static get lang(): string {
    return this._lang;
  }
  //
}
