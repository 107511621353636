import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: "app-star-rate",
  templateUrl: "./star-rate.component.html",
  styleUrls: ["./star-rate.component.scss"]
})
export class StarRateComponent implements OnInit {
  @Input() rate: any;
  @Input() isEditable: boolean = false;
  @Output() starClicked: EventEmitter<number> = new EventEmitter();
  constructor(private cdk: ChangeDetectorRef) {}

  ngOnInit(): void {}
  setRate(startNumber: number) {
    if (!this.isEditable) {
      return;
    }
    this.rate = startNumber;
    this.starClicked.emit(this.rate);
    this.cdk.detectChanges();
  }
}
