import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { ResponseInterface } from "@shared/models/Shared.interfaces";
import { Observable } from "rxjs";
import {
  FUNC_CREATE_CART,
  RETURN_CREATE_CART,
  FUNC_GET_CART,
  TYPE_GET_CART,
  INPUT_GET_CART,
  RETURN_GET_CART,
  FUNC_ADD_TO_CART,
  TYPE_ADD_TO_CART,
  INPUT_ADD_TO_CART,
  RETURN_ADD_TO_CART,
  FUNC_UPDATE_CART_DETAILS,
  INPUT_UPDATE_CART_DETAILS,
  RETURN_UPDATE_CART_DETAILS,
  TYPE_UPDATE_CART_DETAILS,
  FUNC_DELETE_CART_DETAILS,
  RETURN_DELETE_CART_DETAILS,
  TYPE_DELETE_CART_DETAILS,
  INPUT_DELETE_CART_DETAILS,
  FUNC_DECREMENT_CART_DETAILS,
  INPUT_DECREMENT_CART_DETAILS,
  TYPE_DECREMENT_CART_DETAILS,
  RETURN_DECREMENT_CART_DETAILS,
  RETURN_USER_CART,
  FUNC_USER_CART
} from "../model/cart.graphql";
import {
  AddToCartInput,
  AddToCartResponse,
  CreateCartResponse,
  DecrementFromCartInput,
  DecrementFromCartResponse,
  DeleteFromCartResponse,
  GetCartResponse,
  GetUserCart,
  UpdateCartDetailsInput,
  UpdateCartDetailsResponse
} from "../model/cart.models";

@Injectable({ providedIn: "root" })
export class CartService {
  constructor(private readonly baseService: BaseService) {}

  createCart(): Observable<CreateCartResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_CREATE_CART;
    input.return = RETURN_CREATE_CART;
    return this.baseService.generalMutation(input);
  }

  getCart(variables?: any): Observable<GetCartResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_CART;
    input.variable = INPUT_GET_CART;
    input.type = TYPE_GET_CART;
    input.return = RETURN_GET_CART;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }

  addToCart(variables: AddToCartInput): Promise<AddToCartResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_ADD_TO_CART;
    input.variable = INPUT_ADD_TO_CART;
    input.type = TYPE_ADD_TO_CART;
    input.return = RETURN_ADD_TO_CART;
    input.variables = variables;
    return this.baseService.generalMutationFull(input).toPromise();
  }

  updateCartDetails(
    variables: UpdateCartDetailsInput
  ): Promise<UpdateCartDetailsResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_UPDATE_CART_DETAILS;
    input.variable = INPUT_UPDATE_CART_DETAILS;
    input.type = TYPE_UPDATE_CART_DETAILS;
    input.return = RETURN_UPDATE_CART_DETAILS;
    input.variables = variables;
    return this.baseService.generalMutationFull(input).toPromise();
  }

  decrementCartDetailFromCart(
    variables?: DecrementFromCartInput
  ): Observable<DecrementFromCartResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DECREMENT_CART_DETAILS;
    input.variable = INPUT_DECREMENT_CART_DETAILS;
    input.type = TYPE_DECREMENT_CART_DETAILS;
    input.return = RETURN_DECREMENT_CART_DETAILS;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  deleteCartDetailFromCart(
    variables?: DecrementFromCartInput
  ): Observable<DeleteFromCartResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DELETE_CART_DETAILS;
    input.variable = INPUT_DELETE_CART_DETAILS;
    input.type = TYPE_DELETE_CART_DETAILS;
    input.return = RETURN_DELETE_CART_DETAILS;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  getUserCart(): Observable<GetUserCart> {
    const input = {} as QueryFull;
    input.func = FUNC_USER_CART;
    input.return = RETURN_USER_CART;
    return this.baseService.generalQuery(input);
  }
}
