import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { AbstractControl, ControlContainer, FormGroup } from "@angular/forms";

@Component({
  selector: "app-validation-error-message [formGroup] ",
  templateUrl: "./validation-error-message.component.html",
  styleUrls: ["./validation-error-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorMessageComponent implements OnInit, OnChanges {
  form!: FormGroup;
  @Input() controlName!: string;
  @Output() formChange = new EventEmitter<FormGroup>();

  constructor(private controlContainer: ControlContainer) {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
    this.form.valueChanges // subscribe to all changes
      .pipe()
      .subscribe((data) => {
        this.formChange.next(this.form);
      });
  }
}
