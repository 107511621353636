import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { Observable } from "rxjs";
import {
  FUNC_SEARCH,
  INPUT_SEARCH,
  RETURN_SEARCH,
  TYPE_SEARCH
} from "./search.graphql";
import { SearchApiResponse, SearchProductInput } from "./search.model";

@Injectable({ providedIn: "root" })
export class SearchApi {
  constructor(private readonly baseService: BaseService) {}

  search(variables: SearchProductInput): Observable<SearchApiResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SEARCH;
    input.return = RETURN_SEARCH;
    input.variable = INPUT_SEARCH;
    input.type = TYPE_SEARCH;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }
}
