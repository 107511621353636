import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { OrderType } from "src/app/pages/checkout/model/checkout.model";

@Component({
  selector: "app-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptComponent implements OnChanges {
  @Input() order!: OrderType;
  shippingFees: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order.currentValue) {
      console.log(this.order);
    }
  }
}
