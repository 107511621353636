import { translateReturn } from "@shared/utils/utils.methods";

//#region search
export const FUNC_SEARCH = "searchProducts";
export const TYPE_SEARCH = "SearchProductInput!";
export const INPUT_SEARCH = "searchProductInput";
export const RETURN_SEARCH = `{

  results{
    _id
    ${translateReturn("name")}
    image
    price
    modelId
  }
  filter{
    ${translateReturn("categories")}
    designers
  }
}`;
//#endregion
