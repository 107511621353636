import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Buyer } from "@core/auth/models/auth.models";
import {
  AreaType,
  CityType
} from "@shared/models/Shared.interfaces";
import { AddressFacade } from "@shared/services/address/address.facade";
import {
  CreateAddressInput
} from "@shared/services/address/address.model";
import { removeBlankValuesFromKeys } from "@shared/utils/utils.methods";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

@Component({
  selector: "address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"]
})
export class AddressFormComponent implements OnInit, OnChanges {
  states$!: Observable<AreaType[]>;
  cities$!: Observable<CityType[]>;
  @Input() userInfo!: Buyer | null;
  @Output() addressAdded = new EventEmitter<any>();

  form!: FormGroup;
  loading: boolean = false;

  // you can put this method in a module and reuse it as needed

  constructor(
    private readonly addressFacade: AddressFacade,
    private fb: FormBuilder,
    private readonly toastr: ToastrService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userInfo.currentValue) {
      if (this.userInfo) {
        this.initForm(this.userInfo);
      }
    }
  }

  ngOnInit(): void {
    this.cities$ = this.addressFacade.getCities$();
    this.states$ = this.addressFacade.getStates$();
  }

  initForm(userInfo: Buyer): void {
    this.form = this.fb.group({
      country: ["Egypt", Validators.required],
      fName: [userInfo?.fName, Validators.required],
      lName: [userInfo?.lName, Validators.required],
      email: [userInfo?.email, Validators.required],
      apartment: ["1", Validators.required],
      city: ["", Validators.required],
      cityId: ["", Validators.required],

      state: ["State", Validators.required],
      zipCode: [1, Validators.required],
      address: ["", Validators.required],
      building: ["1", Validators.required],
      floorNo: [1, Validators.required],
      code: ["02", Validators.required],
      phone: [
        userInfo?.phone,
        [Validators.required, Validators.pattern("(01)[0-9]{9}")]
      ]
    });
  }

  changeCity(event: any): void {
    const city: CityType = JSON.parse(event.target.value) as CityType;
    this.addressFacade.selectState;
    this.addressFacade.selectState(city.areas);
    this.form.patchValue({ city: city.name || city.arName });
    this.form.patchValue({ cityId: city._id });
    console.log(this.form.controls)
  }

  changeState(event: any): void {
    const state: AreaType = JSON.parse(event.target.value) as AreaType;
    this.form.patchValue({ state: state.name });
  }

  async submit(): Promise<void> {
    this.loading = true;

    try {
      this.addAddress();
    } catch (error) {
      this.loading = false;
    }
  }

  async addAddress(): Promise<void> {
    const removedBlankedKeys = removeBlankValuesFromKeys(this.form.value);
    const input: CreateAddressInput = {
      addAddressInput: {
        ...removedBlankedKeys
      }
    };
    const isAdded = await this.addressFacade.createAddress$(input).toPromise();
    if (isAdded) {
      this.addressAdded.emit(true);
      this.toastr.success("Address added successfully");
      this.loading = false;
      this.form.reset();
    }
  }

  get phoneNumber() {
    return this.form.get("phone");
  }

  get zipCode() {
    return this.form.get("zipCode");
  }
  get streetName() {
    return this.form.get("address");
  }
  get building() {
    return this.form.get("building");
  }
  get floorNo() {
    return this.form.get("floorNo");
  }
}
