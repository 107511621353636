import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
@Injectable({
  providedIn: "root"
})
export class LocalStorageService {
  private test: any;
  constructor(
    @Inject(LOCAL_STORAGE) private readonly storage: StorageService
  ) {}

  set(key: string, value: string): void {
    this.storage.set(key, value);
  }

  get(key: string) {
    return this.storage.get(key);
  }

  clear(): void {
    this.storage.remove("AUTH_TOKEN");
    this.storage.remove("cartId");
    // this.storage.remove("beta-user");
    // this.storage.clear();
  }

  remove(key: string): void {
    this.storage.remove(key);
  }
}
