<ng-container *ngIf="form">
  <div [formGroup]="form">
    <div
      *ngIf="
        form.get(controlName)?.invalid &&
        form.get(controlName)?.errors &&
        (form.get(controlName)?.dirty || form.get(controlName)?.touched)
      "
    >
      <ul class="mt-2">
        <li *ngIf="form.get(controlName)?.hasError('required')">
          <small class="text-danger">
            {{ "shared.fieldRequired" | translate }}
          </small>
        </li>

        <!-- <li *ngIf="form.get(controlName)?.hasError('email')">
          <small class="text-danger"> Wrong Email Pattern </small>
        </li> -->

        <li *ngIf="form.get(controlName)?.hasError('minlength')">
          <small class="text-danger">
            {{
              "shared.minimumLengthError"
                | translate
                  : {
                      length:
                        form.get(controlName)?.errors?.minlength.requiredLength
                    }
            }}|
          </small>
        </li>

        <li *ngIf="form.get(controlName)?.hasError('maxlength')">
          <small class="text-danger">
            {{
              "shared.maximumLengthError"
                | translate
                  : {
                      length:
                        form.get(controlName)?.errors?.maxlength.requiredLength
                    }
            }}
          </small>
        </li>

        <li *ngIf="form.get(controlName)?.hasError('pattern')">
          <small class="text-danger">
            {{ "shared.patternWrong" | translate }}
          </small>
        </li>

        <li *ngIf="form.get(controlName)?.hasError('email')">
          <small class="text-danger">
            {{ "shared.patternWrong" | translate }}
          </small>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
