<div class="container my-2 d-flex flex-column align-items-end">
  <!-- <button type="button" class="btn btn-outline-primary mt-4 px-4">
    Print copy
  </button> -->
  <div class="my-3 p-5 receipt-container">
    <div class="d-flex flex-column my-3">
      <h3>{{ "checkout.billingInformation" | translate }}</h3>

      <!-- <span
        >{{ order?.billingAddress?.fName + " "
        }}{{ order?.billingAddress?.lName }}
      </span> -->
      <span> {{ order?.billingAddress?.phone }}</span>
      <span>
        {{
          order?.billingAddress?.address + ", " + order?.billingAddress?.city
        }}</span
      >
    </div>
    <hr />

    <div class="row my-3">
      <h3>{{ "bundle.items" | translate }}</h3>
      <table class="appTassble">
        <tbody>
          <tr *ngFor="let item of order?.cart?.storedProduct; let i = index">
            <td>
              <div class="appTable-item">
                <div class="appTable-item-header">
                  <!--  [src]="item.imageModel.path" -->
                  <app-image [src]="item.imageModel.path"></app-image>
                </div>
                <div class="appTable-item-body">
                  <span class="fs-xs">
                    <a [routerLink]="['/product', item.product._id]">
                      {{ item.product.name || item.product.arName }}
                    </a>
                  </span>
                  <!-- <p class="mb-0">Color: Dark Wood</p>
                  <p class="mb-0">Material: MDF</p>
                  <p class="mb-0">Size: 120 cm</p> -->
                </div>
              </div>
            </td>
            <div></div>
            <td style="text-align: end">
              {{ "account.qty" | translate }} : {{ item.quantity }}
            </td>

            <td style="text-align: end">
              {{ "shared.price" | translate }} : {{ item.priceOneEg }}
            </td>
            <td style="text-align: end">
              {{ "checkout.subtotal" | translate }} : {{ item.subtotal }}
            </td>

            <!-- <td class="fw-medium fs-xs">{{ item.product.egPrice }} EGP</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <hr />

    <div class="row my-3">
      <h3>{{ "checkout.order-summary" | translate }}</h3>
      <div class="d-flex justify-content-between">
        <strong>{{ "checkout.subtotal" | translate }} </strong>
        <strong> {{ order?.subtotalPrice }}</strong>
      </div>
      <div class="d-flex justify-content-between">
        <strong>{{ "checkout.shippingFees" | translate }}</strong>
        <strong> {{ order.totalShippingPrice }}</strong>
      </div>

      <div class="d-flex justify-content-between" *ngIf="order?.discount">
        <strong>{{ "account.discount" | translate }}</strong>
        <strong> {{ order.discount }}</strong>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <h5>{{ "checkout.total" | translate }}</h5>
        <strong>{{ order?.totalPrice }}</strong>
      </div>
    </div>
  </div>
</div>
