import { Injectable } from "@angular/core";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { Category, VariantType } from "@shared/models/Shared.interfaces";
import { ProductType } from "@shared/services/product/product.model";
import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Setting } from "src/app/pages/home/model/home.model";
import { FooterService } from "./footer.service";

@Injectable({
  providedIn: "root"
})
export class FooterFacade {
  private _settingSubject: Subject<Setting> = new Subject();

  constructor(private readonly footerService: FooterService) {
    this.setSetting();
  }

  //#region Implementation
  private setSetting(): void {
    this.footerService.getSetting().subscribe((res) => {
      this._settingSubject.next(res.data.settings);
    });
  }
  //#endregion

  //#region Public methods (interface)
  public getSetting$(): Observable<Setting> {
    return this._settingSubject.asObservable();
  }
  //#endregion
}
