<ul class="mobileNav">
  <li>
    <a href="javascript:void(0)" (click)="CartSidebarService.Open()">
      <svg-icon src="assets/icons/cart.svg"></svg-icon>
      <span>{{ "tabs.cart" | translate }}</span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/all-categories']">
      <svg-icon src="assets/icons/category.svg"></svg-icon>
      <span>{{ "tabs.shop" | translate }}</span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/wishlist']">
      <svg-icon src="assets/icons/heart.svg"></svg-icon>
      <span>{{ "tabs.whishlist" | translate }}</span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/account']">
      <svg-icon src="assets/icons/person.svg"></svg-icon>
      <span>{{ "tabs.account" | translate }}</span>
    </a>
  </li>
</ul>

<div class="mobileNav-spacing"></div>
