<div class="theHeader">
  <ng-template #tmpl>
    <div class="theHeader-search" [formGroup]="searchForm">
      <input
        myAutofocus
        class="form-control search-input"
        type="text"
        formControlName="searchKeyword"
        placeholder="{{ 'header.searchFor' | translate }}"
      />
      <!-- <button class="btn">
        <svg-icon src="assets/icons/search.svg"></svg-icon>
      </button> -->
      <!-- *ngIf="products?.length  && searchKey" -->
      <ng-template #noSearchProducts>
        <div
          class="theHeader-search-results"
          *ngIf="searchForm.value.searchKeyword"
        >
          <div class="w-100 p-4 mb-2">
            {{ "header.no-products-search" | translate }}
          </div>
        </div>
      </ng-template>
      <ng-container
        *ngIf="
          searchProducts && searchProducts.length > 0;
          else noSearchProducts
        "
      >
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          *ngIf="searchProducts.length > 0"
        >
          <div class="theHeader-search-results">
            <div class="w-100 p-4 filter-container mb-2">
              <div
                class="
                  categories-container
                  d-flex
                  flex-wrap
                  justify-content-start
                "
              >
                <span
                  *ngFor="
                    let cat of searchFilters.categories ||
                      searchFilters.arCategories;
                    let i = index
                  "
                  [class.is-active-cat]="selectedCategoriesFilterSearch[i]"
                  (click)="categorySearchFilter(cat, i)"
                  class="py-2 my-2 px-4 mx-2"
                  [innerHTML]="cat"
                  >{{ cat }}</span
                >
              </div>
              <div class="price-container w-100 d-flex justify-content-start">
                <div class="col-12">
                  <div class="mt-4">
                    <h5 class="fs-xs mb-0">{{ "shared.price" | translate }}</h5>
                  </div>
                  <div class="productsFilter-item-body">
                    <ngx-slider
                      (userChangeEnd)="changePrice($event)"
                      [(highValue)]="highValue"
                      [(value)]="PriceValue"
                      [options]="options"
                    ></ngx-slider>
                  </div>
                </div>
              </div>
            </div>

            <ul
              style="max-height: 30rem; overflow-y: auto"
              *ngIf="noResultsOnPriceRange; else noResultOnPriceRange"
            >
              <li
                appDropDownNavigate
                *ngFor="let item of searchProducts | slice: 0:3; let i = index"
                (click)="clearSearchProducts()"
                class="d-flex align-items-center li-search border"
                [routerLink]="['/product/', item._id]"
                style="cursor: pointer"
              >
                <div style="width: 7rem !important">
                  <app-image [src]="item.image"></app-image>
                </div>
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-between
                    align-items-between
                    mx-2
                  "
                >
                  <span
                    (click)="closeSearchDialog()"
                    style="font-weight: bold"
                    [routerLink]="['/product/', item._id]"
                    [innerHtml]="item.name || item.arName"
                  ></span>
                  <span style="font-weight: bold" class="text-primary"
                    >{{ item.price }} {{"shared.egp" | translate}}</span
                  >
                </div>
              </li>
              <br />

              <div class="categories-container d-flex justify-content-center">
                <span
                  (click)="closeSearchDialog()"
                  class="py-2 px-4 mx-2 text-primary border"
                  routerLink="search"
                  [queryParams]="{
                    keyword: searchForm.controls.searchKeyword.value
                  }"
                  style="cursor: pointer"
                >
                  {{ "shared.load-more" | translate }}
                </span>
              </div>
            </ul>
            <ng-template #noResultOnPriceRange>
              <div class="w-100 p-4 mb-2">
                {{ "header.no-products-search" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <div class="theHeader-start">
    <div class="container">
      <a class="theHeader-logo" [routerLink]="['/']" title="Romeo">
        <img src="assets/icons/logo.svg" alt="Kemitt" />
      </a>
      <div class="theHeader-search" [formGroup]="searchForm">
        <div class="form-control" (click)="openSearchDialog(tmpl)">
          {{ "header.searchFor" | translate }}
        </div>
      </div>

      <ul class="theHeader-actions">
        <li>
          <a
            style="cursor: pointer"
            title="ع"
            (click)="changeLang('ar')"
            *ngIf="currentLang == 'en'"
          >
            <svg-icon src="assets/icons/ar.svg"></svg-icon>
          </a>

          <a
            style="cursor: pointer"
            title="English"
            *ngIf="currentLang == 'ar'"
            (click)="changeLang('en')"
          >
            E
            <!-- <svg-icon src="assets/icons/.svg"></svg-icon> -->
          </a>
        </li>
        <li class="d-none d-md-flex">
          <a [routerLink]="['/wishlist']" title="wishlist">
            <svg-icon src="assets/icons/heart.svg"></svg-icon>
            <span class="theHeader-actions-num">{{
              (favourites?.length || 0) + (bundleFavourites?.length || 0) || 0
            }}</span>
          </a>
        </li>
        <li class="d-none d-md-flex">
          <a [routerLink]="['/account']">
            <svg-icon
              src="assets/icons/person.svg"
              style="color: black"
            ></svg-icon>
          </a>
        </li>
        <li class="d-none d-md-flex">
          <a href="javascript:void(0)" title="Cart" [routerLink]="['checkout']">
            <svg-icon src="assets/icons/cart.svg"></svg-icon>
            <span class="theHeader-actions-num">
              {{ (cartDetails$ | async)?.cartSummary?.items || 0 }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="theHeader-end">
    <div class="container position-relative">
      <div class="theHeader-end-container">
        <ul class="theHeader-nav">
          <li
            class="megaMenu-parent show-shadow"
            (mouseenter)="allCategoriesHovered($event)"
            (mouseleave)="navLeave($event)"
          >
            <a
              [routerLink]="['/all-categories']"
              title="{{ 'header.allCategories' | translate }}"
              class="fw-bold"
            >
              {{ "header.allCategories" | translate }}</a
            >

            <div class="megaMenu">
              <ul class="megaMenu-categories">
                <ng-container *ngFor="let item of allCategories">
                  <li>
                    <a
                      [routerLink]="['/products']"
                      [queryParams]="{ category: item._id }"
                      [attr.title]="item?.name || item?.arName"
                      (mouseenter)="changeCategory(item)"
                    >
                      {{ item.name || item.arName }}</a
                    >
                  </li>
                </ng-container>
                <li class="text-decoration-underline font-weight-bold">
                  <strong>
                    <a [routerLink]="['/all-categories']">{{
                      "shared.see-all-cats" | translate
                    }}</a>
                  </strong>
                </li>
              </ul>
              <!-- Sub menu -->
              <div class="megaMenu-categories-body" *ngIf="activeCategory">
                <div class="megaMenu-categories-item">
                  <h5 class="fs-xs">
                    {{ activeCategory.name || activeCategory.arName }}
                  </h5>
                  <ul class="megaMenu-categories-item-nav">
                    <li *ngFor="let item of activeCategory?.subCategories">
                      <a
                        [queryParams]="{
                          category: activeCategory._id,
                          subCategory: item._id
                        }"
                        [routerLink]="['/products']"
                        [attr.title]="item.name || item.arName"
                      >
                        {{ item.name || item.arName }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
            </div>
          </li>

          <!-- middle categories -->
          <li
            *ngFor="let item of popularCategories"
            class="megaMenu-parent show-shadow"
            (mouseenter)="categoryHovered($event, item)"
            (mouseleave)="navLeave($event)"
          >
            <a
              [routerLink]="['/products']"
              [queryParams]="{ category: item._id }"
            >
              {{ item.name || item.arName }}
            </a>

            <!-- Sub Categories -->
            <div class="megaMenu">
              <ul class="megaMenu-categories">
                <li *ngFor="let subCategory of activeSubCategories">
                  <a
                    [queryParams]="{
                      category: item._id,
                      subCategory: subCategory._id
                    }"
                    [routerLink]="['/products']"
                    [attr.title]="subCategory.name || subCategory.arName"
                    >{{ subCategory.name || subCategory.arName }}</a
                  >
                </li>
              </ul>

              <!-- <div class="megaMenu-body w-100" *ngIf="activeSubCategory">
                <div class="row">
                  <div class="col-6">
                    <a
                      [routerLink]="['/product', activeSubCategory.image]"
                      class="b-block"
                    >
                      <app-image
                        [src]="activeSubCategory.image"
                        ratio="is-2-1 rounded"
                      ></app-image>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      [routerLink]="['/product', activeSubCategory.image]"
                      class="b-block"
                    >
                      <app-image
                        [src]="activeSubCategory.image"
                        ratio="is-2-1 rounded"
                      ></app-image>
                    </a>
                  </div>
                </div>
              </div> -->

              <div class="megaMenu-body w-100 mb-5" *ngIf="activeCategory">
                <div class="row">
                  <div
                    class="col-6"
                    style="position: relative"
                    *ngFor="let item of twoRandomsSubCategories; let i = index"
                  >
                    <a
                      [routerLink]="['/products']"
                      [queryParams]="{
                        category: item.catId,
                        subCategory: item.subCatId
                      }"
                      class="b-block"
                    >
                      <app-image
                        [src]="item.img"
                        ratio="is-2-1 rounded"
                      ></app-image>
                      <div
                        class="
                          shop-now-subcategory
                          d-flex
                          flex-column
                          align-items-center
                          justify-content-center
                          py-3
                        "
                      >
                        <span>{{
                          "header.best-deals"
                            | translate: { cat: item.subCatName }
                        }}</span>

                        <h4>{{ "shared.shop-now" | translate }}</h4>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </li>

          <!--  -->
          <li style="font-family: arial">|</li>
          <li class="text-danger">
            <a
              [routerLink]="['/products']"
              title="Deals"
              [queryParams]="{
                onSale: true
              }"
            >
              {{ "header.deals" | translate }}</a
            >
          </li>
          <li>
            <a [routerLink]="['/collections']" title="Collections">
              {{ "header.collections" | translate }}</a
            >
          </li>
          <li>
            <a [routerLink]="['/bundles']" title="Bundels"> Bundles</a>
          </li>
          <!-- <div class="overlay"></div> -->
        </ul>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showOverlay" class="overlay"></div>

<!-- <app-search-popup></app-search-popup> -->
