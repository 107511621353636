export const INPUT_PAGINATION = "paginationOptions";
export const TYPE_PAGINATION = "PaginationOptions!";

//#region buyer
export const FUNC_BUYER = "user";
export const RETURN_BUYER = `{
  name
  fName
  lName
  email
  phone
  address{
    _id
    city
    cityId
    phone
    address
  }
}`;
//#endregion
