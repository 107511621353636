import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonFacade } from "@core/facades/common.facade";
import { HandleErrorsFacade } from "@core/facades/handle-errors.facade";
import { QueryFull } from "@core/models/graph-models";
import { FavouriteFacade } from "@shared/services/favourite/favourite.facade";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  AccessToken,
  AuthCredentialInput,
  Buyer,
  BuyerSignUpDetailsInput,
  DesignerSignUpDetailsInput,
  DesignerSignUpResponse,
  ForgetPasswordInput,
  ForgetPasswordResponse,
  SetNewPasswordResponse,
  SignInResponse,
  SignUpResponse,
  SocialSignInInput
} from "../models/auth.models";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthFacade {
  private _isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private readonly authService: AuthService,
    private readonly commonFacade: CommonFacade
  ) {}

  isUserLoggedIn(): boolean {
    return this._isUserLoggedIn.getValue();
  }

  isUserLoggedIn$(): Observable<boolean> {
    return this._isUserLoggedIn.asObservable();
  }

  //#region Public methods (interface)
  public setAccessToken(accessToken: string): void {
    this.commonFacade.setItem("AUTH_TOKEN", accessToken);
    this.setUserStatus(true);
  }

  userValidation(): void {
    if (this.commonFacade.getItem("AUTH_TOKEN")) {
      this.userValidation$().subscribe(
        (data) => {
          this.setUserStatus(true);
        },
        (err) => {
          this.setUserStatus(false);
          localStorage.removeItem("AUTH_TOKEN");
        }
      );
    } else {
      this.setUserStatus(false);
    }
  }

  setUserStatus(value: boolean): void {
    this._isUserLoggedIn.next(value);
  }

  signOut(): void {
    this.commonFacade.clearLocalStorage();
    this.commonFacade.navigate(["/"]);
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  signUp$(data: BuyerSignUpDetailsInput): Observable<SignUpResponse> {
    return this.authService.signUp(data);
  }

  signUpDesigner$(
    data: DesignerSignUpDetailsInput
  ): Observable<DesignerSignUpResponse> {
    return this.authService.designerSignUp(data);
  }
  signIn$(data: AuthCredentialInput): Observable<SignInResponse> {
    return this.authService.signIn(data).pipe(
      tap((res) => {
        this.setAccessToken(res.data.signIn.accessToken);
      })
    );
  }

  signInSocial$(variables: SocialSignInInput): Observable<AccessToken> {
    return this.authService
      .signInSocial(variables)
      .pipe(map((x) => x.data.socialSignIn));
  }

  forgetPassword$(
    data: ForgetPasswordInput
  ): Observable<ForgetPasswordResponse> {
    return this.authService.forgetPassword(data);
  }

  setNewPassword$(data: any): Observable<SetNewPasswordResponse> {
    return this.authService.setNewPassword(data);
  }

  userValidation$(): Observable<Buyer> {
    return this.authService.userValidation().pipe(map((x) => x.data.user));
  }
  userPaymentInfo$(): Observable<Buyer> {
    return this.authService.userPaymentInfo().pipe(map((x) => x.data.user));
  }
}
