import { translateReturn } from "@shared/utils/utils.methods";

//#region addToCart
export const FUNC_ADD_TO_CART = "addToCart";
export const TYPE_ADD_TO_CART = "AddToCartInput!";
export const INPUT_ADD_TO_CART = "addToCartInput";
export const RETURN_ADD_TO_CART = `{
  _id
  createdIn
  storedProduct{
    _id
    image
    quantity
    product{
      _id
    }
  }
}`;

//#endregion

//#region UpdateCartDetails
export const FUNC_UPDATE_CART_DETAILS = "updateCartDetail";
export const TYPE_UPDATE_CART_DETAILS = "UpdateCartDetailsInput!";
export const INPUT_UPDATE_CART_DETAILS = "updateCartDetail";
export const RETURN_UPDATE_CART_DETAILS = `{
  qty
  price
  cart {

  cartId
    cartDetails{
      price
      qty
    }
  }
}`;
//#endregion

//#region deleteCartItem
export const FUNC_DELETE_CART_DETAILS = "deleteFromCart";
export const TYPE_DELETE_CART_DETAILS = "DeleteFromCartInput!";
export const INPUT_DELETE_CART_DETAILS = "decrementFromCartInput";
export const RETURN_DELETE_CART_DETAILS = `{
  _id
  createdIn
  storedProduct{
    _id
    image
    quantity
    product{
      _id
    }
  }
}`;
//#endregion

//#region DecrementCartItem
export const FUNC_DECREMENT_CART_DETAILS = "decrementFromCart";
export const TYPE_DECREMENT_CART_DETAILS = "DecrementFromCartInput!";
export const INPUT_DECREMENT_CART_DETAILS = "decrementFromCartInput";
export const RETURN_DECREMENT_CART_DETAILS = `{
  _id
  createdIn
  storedProduct{
    _id
    image
    quantity
    product{
      _id
    }
  }
}`;
//#endregion

//#region createCart
export const FUNC_CREATE_CART = "createCart";
export const RETURN_CREATE_CART = `{
  _id
createdIn
storedProduct{
  _id
  image
  quantity
  product{
    _id
  }
}
}`;
//#endregion

//#region cart
export const FUNC_GET_CART = "cart";
export const INPUT_GET_CART = "getCartInput";
export const TYPE_GET_CART = "GetCartInput!";
export const RETURN_GET_CART = `{
  _id
  createdIn

  storedProduct{
    _id
    image
    priceOneEg
    subtotal
    priceOfQuantityEg
    imageModel{
      features{
       _id
       ${translateReturn("value")}
       ${translateReturn("property")}

      }
      _id
      path
      originalName
      egExtraPrice
      suExtraPrice
      emExtraPrice
    }
    product{
      ${translateReturn("name")}
      _id
      egPrice
      isAvailableForCod
      requiredTimeForEgypt
      category{
        _id
      }
    }
    quantity
    product{
      _id
    }
  }


  storedBundles {
    _id
    bundle{
      _id
      ${translateReturn("name")}
      mainImage
      isAvailableForCod
      requiredTimeForDelivery
    }
    priceOne
    subtotal
    quantity
    totalPrice
    }

}`;

//#endregion

//#region user cart
export const FUNC_USER_CART = "userCart";
export const RETURN_USER_CART = `{
 _id
}`;
//#endregion
