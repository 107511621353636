import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";

@Component({
  selector: "app-empty-list",
  templateUrl: "./empty-list.component.html",
  styleUrls: ["./empty-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListComponent implements OnInit {
  @Input() src!: string;
  constructor() {}

  ngOnInit(): void {}
}
