import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AuthCredentialInput,
  BuyerSignUpDetailsInput
} from "@core/auth/models/auth.models";
import { AuthFacade } from "@core/auth/services/auth.facade";
import { HandleErrorsFacade } from "@core/facades/handle-errors.facade";
import { removeBlankValuesFromKeys } from "@shared/utils/utils.methods";
import { mergeMap } from "rxjs/operators";

@Component({
  selector: "app-register-form",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  loading: boolean = false;
  matched: boolean = false;
  signUpForm!: FormGroup;
  @Output() formSubmitted = new EventEmitter<BuyerSignUpDetailsInput>();

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.initSignUpForm();
    // this.setEmailValue();
  }

  navigateToLogin(): void {
    this.router.navigate(["/auth/login"]);
  }
  initSignUpForm(): void {
    this.signUpForm = this.fb.group(
      {
        fName: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z ]*$"),
            Validators.maxLength(15)
          ]
        ],
        lName: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z ]*$"),
            Validators.maxLength(15)
          ]
        ],
        fbID: [""],
        ggID: [""],
        phone: ["", [Validators.pattern("(01)[0-9]{9}")]],
        email: ["", [Validators.email]],
        password: ["", [Validators.required]]
      },
      { validator: atLeastOne(Validators.required, ["email", "phone"]) }
    );
  }

  // setEmailValue(): void {
  //   this.signUpForm.patchValue({
  //     email: this.activateRoute.snapshot.queryParamMap.get("email")
  //   });
  // }

  signUp(): void {
    // this.loading = true;
    const input: BuyerSignUpDetailsInput = {
      userSignUpDetailsInput: {
        fName: this.signUpForm.value.fName,
        lName: this.signUpForm.value.lName,
        phone: this.signUpForm.value.phone,
        email: this.signUpForm.value.email,
        password: this.signUpForm.value.password
      }
    };

    this.formSubmitted.emit(input);
  }

  // Form Validations
  get email() {
    return this.signUpForm.get("email");
  }

  get buyerFirstName() {
    return this.signUpForm.get("fName");
  }

  get buyerLastName() {
    return this.signUpForm.get("lName");
  }

  get password() {
    return this.signUpForm.get("password");
  }

  get phoneNumber() {
    return this.signUpForm.get("phone");
  }

  // get confirmPassword() {
  //   // this.matchPasswords();
  //   return this.signUpForm.get("confirmPassword");
  // }

  // matchPasswords() {
  //   if (
  //     this.signUpForm.value.confirmPassword == this.signUpForm.value.password
  //   ) {
  //     this.matched = true;
  //   } else {
  //     this.matched = false;
  //   }
  // }
}
export const atLeastOne =
  (validator: ValidatorFn, controls: any = null) =>
  (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne =
      group &&
      group.controls &&
      controls.some((k: any) => !validator(group.controls[k]));

    return hasAtLeastOne
      ? null
      : {
          atLeastOne: true
        };
  };
