import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchApi } from "./search.api";

import {
  ProductSearchResultType,
  SearchProductInput,
  SearchResultType
} from "./search.model";

@Injectable({ providedIn: "root" })
export class SearchFacade {
  constructor(private readonly searchApi: SearchApi) {}

  search(searchValues: SearchProductInput): Observable<SearchResultType> {
    const variables: SearchProductInput = searchValues;
    return this.searchApi
      .search(variables)
      .pipe(map((x) => x.data.searchProducts));
  }
}
