<div class="postCard">
  <a class="postCard-url" title="Kemitt Participate in GTIEX 2019"></a>
  <div class="postCard-header">
    <div class="postCard-header-thumb">
      <app-image
        alt="Kemitt Participate in GTIEX 2019"
        ratio="is-3-2"
        [src]="post.image"
      ></app-image>
    </div>
  </div>
  <div class="postCard-body">
    <ul class="product-tags mb-2">
      <li *ngFor="let tag of post?.postTags">
        <a
          [routerLink]="['/../products']"
          [queryParams]="{
            tag: tag._id
          }"
          [attr.title]="tag.name || tag.arName"
          >{{ tag.name || tag.arName }}</a
        >
      </li>
    </ul>
    <h4 class="fs-sm mb-0">
      <a [routerLink]="['/blog', post._id]">{{
        post?.title || post?.arTitle
      }}</a>
    </h4>
  </div>
</div>
