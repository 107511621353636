<div class="cartSidebar">
  <div class="cartSidebar-bg" (click)="CartSidebarService.Close()"></div>
  <div class="cartSidebar-container">
    <div class="text-center mb-md">
      <button
        class="cartSidebar-close btn cartSidebar-close btn"
        (click)="CartSidebarService.Close()"
      >
        <svg-icon src="assets/icons/close.svg" class="fs-md"></svg-icon>
      </button>
    </div>

    <!-- <div class="pe-lg ps-lg">
      <div class="mb-md">
        <p class="fw-medium">
          <svg-icon
            src="assets/icons/truck.svg"
            class="me-xxs fs-lg"
          ></svg-icon>
          Add <span class="text-primary">150EGP</span> To get free shipping
        </p>
        <div class="cartSidebar-progress">
          <span class="cartSidebar-progress-inner" style="width: 80%"></span>
        </div>
      </div>
    </div> -->

    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        fw-medium
        fs-md
        pe-lg
        ps-lg
      "
    >
      <span>{{ "checkout.myCart" | translate }}</span>
      <span class="text-primary"
        >{{
          (cartDetails$ | async)?.cartSummary?.totalPriceNumber || 0
        }}
        {{"shared.egp" | translate}}</span
      >
    </div>
    <div>
      <div
        class="cartSidebar-item"
        *ngFor="let item of (cartDetails$ | async)?.cartItems; let i = index"
      >
        <a
          class="cartSidebar-item-header"
          [routerLink]="['/product', item.product._id]"
          (click)="CartSidebarService.Close()"
        >
          <app-image [src]="item.imageModel.path" ratio="is-1-1"></app-image>
        </a>
        <div class="cartSidebar-item-body">
          <p class="mb-0 fw-medium">
            <a
              [routerLink]="['/product', item.product._id]"
              [title]="item.product.name"
              (click)="CartSidebarService.Close()"
            >
              {{ item.product.name || item.product.arName }}</a
            >
            <span> ({{ item.priceOneEg }} EGP) </span>
          </p>
          <span class="fw-medium text-primary">
            {{ item.subtotal }}
            {{"shared.egp" | translate}}
          </span>
        </div>

        <div class="cartSidebar-item-footer">
          <div class="qtyInput max-100">
            <button
              class="qtyInput-icon border-0"
              [disabled]="loadingQtyIndex == i"
              (click)="removeCartItemDetails(item)"
            >
              -
            </button>
            <!-- *ngIf="loadingQtyIndex != i; else qtySpinner" -->
            <ng-container>
              <div>
                {{ item.quantity }}
              </div>
            </ng-container>
            <ng-template #qtySpinner>
              <div class="spinner-border"></div>
            </ng-template>
            <button
              class="qtyInput-icon border-0"
              [disabled]="loadingQtyIndex == i"
              (click)="addToCart(item)"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div
        class="cartSidebar-item"
        *ngFor="let item of (cartDetails$ | async)?.cartBundles; let i = index"
      >
        <a
          class="cartSidebar-item-header"
          [routerLink]="['/bundle', item.bundle._id]"
          (click)="CartSidebarService.Close()"
        >
          <app-image [src]="item.bundle.mainImage" ratio="is-1-1"></app-image>
        </a>
        <div class="cartSidebar-item-body">
          <p class="mb-0 fw-medium">
            <a
              [routerLink]="['/bundle', item.bundle._id]"
              [title]="item.bundle.name"
              (click)="CartSidebarService.Close()"
            >
              {{ item.bundle.name || item.bundle.arName }}</a
            >
            <span> ({{ item.priceOne }} EGP) </span>
          </p>
          <span class="fw-medium text-primary">
            {{ item.subtotal }}
            {{"shared.egp" | translate}}
          </span>
        </div>

        <div class="cartSidebar-item-footer">
          <div class="qtyInput max-100">
            <button
              class="qtyInput-icon border-0"
              [disabled]="loadingQtyIndex == i"
              (click)="decrementBundle(item._id)"
            >
              -
            </button>
            <!-- *ngIf="loadingQtyIndex != i; else qtySpinner" -->
            <ng-container>
              <div>
                {{ item.quantity }}
              </div>
            </ng-container>
            <ng-template #qtySpinner>
              <div class="spinner-border"></div>
            </ng-template>
            <button
              class="qtyInput-icon border-0"
              [disabled]="loadingQtyIndex == i"
              (click)="addBundleToCart(item._id)"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="pe-lg ps-lg">
      <button
        class="btn btn-primary w-100 rounded-pill"
        [routerLink]="['/checkout']"
        title="Checkout"
        (click)="CartSidebarService.Close()"
        [disabled]="!(cartDetails$ | async)?.cartSummary?.items"
      >
        {{ "checkout.checkout" | translate }}
      </button>

      <button
        (click)="toggleCartSidebar()"
        class="btn btn-outline-primary w-100 rounded-pill my-2"
      >
        {{ "shared.continue-shopping" | translate }}
      </button>
    </div>
    <!--
    <div class="fw-medium fs-sm pe-lg ps-lg mt-lg">
      <span>We think you'll love </span>
    </div>
    <div>
      <div
        class="cartSidebar-item"
        *ngFor="let item of [].constructor(4); let i = index"
      >
        <a
          class="cartSidebar-item-header"
          [routerLink]="['/product']"
          (click)="CartSidebarService.Close()"
        >
          <app-image ratio="is-1-1"></app-image>
        </a>
        <div class="cartSidebar-item-body">
          <p class="mb-0 fw-medium">
            <a
              [routerLink]="['/product']"
              title="Sofa, Beech wood, Light Beige"
              (click)="CartSidebarService.Close()"
            >
              Sofa, Beech wood, Light Beige</a
            >
          </p>
          <span class="fw-medium text-primary">112EGP</span>
        </div>
        <div class="cartSidebar-item-footer">
          <div class="cartSidebar-item-button">
            <svg-icon src="assets/icons/cart.svg"></svg-icon>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
