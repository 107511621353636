import { Directive, ElementRef, Input, OnChanges, OnInit } from "@angular/core";
import { CollectionsRoutingModule } from "src/app/pages/collections/collections-routing.module";

@Directive({
  selector: "[buttonLoader]"
})
export class ButtonLoaderDirective implements OnInit, OnChanges {
  @Input() loadingState!: boolean;
  contentText!: string;

  constructor(private elem: ElementRef) {}

  ngOnInit(): void {
    if (this.elem.nativeElement.firstChild !== "undefined") {
      this.contentText = this.elem.nativeElement.textContent;
    }
    if (this.loadingState) {
      this.elem.nativeElement.disabled = true;

      this.elem.nativeElement.innerHTML =
        '<div class="spinner-border custom-spinner-btn"></div>';
    }
  }

  ngOnChanges(changes: any): void {
    this.loadingState = changes.loadingState?.currentValue;
    if (this.loadingState) {
      this.elem.nativeElement.disabled = true;
      this.elem.nativeElement.innerHTML =
        '<div class="spinner-border  custom-spinner-btn"></div>';
    } else {
      if (this.contentText) {
        setTimeout(() => {
          this.elem.nativeElement.innerHTML = this.contentText;
          this.elem.nativeElement.disabled = false;
        }, 1000);
      }
    }
  }
}
