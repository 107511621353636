import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  Input,
  Renderer2,
  ElementRef,
  ViewChild
} from "@angular/core";
import { ModalService } from "@shared/services/modal/modal.service";

@Component({
  selector: "app-search-popup",
  templateUrl: "./search-popup.component.html",
  styleUrls: ["./search-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPopupComponent implements OnInit {
  @Input() tmpl!: TemplateRef<any>;
  @ViewChild("container") container!: ElementRef;
  isDialogOpened: boolean = false;

  constructor(private readonly modalService: ModalService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.isDialogOpened = true;
    }, 100);
  }

  closeDialiog(e?: any) {
    if (this.isDialogOpened) {
      this.modalService.closeDialog();
      this.isDialogOpened = false;
    }
  }
}
