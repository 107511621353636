import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SwiperModule } from "swiper/angular";
import { ImageComponent } from "./image.component";
import { LazyLoadImageModule } from "ng-lazyload-image";

@NgModule({
  declarations: [ImageComponent],
  imports: [CommonModule, SwiperModule, LazyLoadImageModule],
  exports: [ImageComponent]
})
export class ImageModule {}
