import { Injectable, TemplateRef } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SearchPopupComponent } from "@shared/components/search-popup/search-popup.component";

@Injectable({ providedIn: "root" })
export class ModalService {
  constructor(private modalService: NgbModal) {}

  displaySearchModal(tmpl: TemplateRef<any>) {
    const option: NgbModalOptions = {
      backdropClass: "modal-dialog-centered"
    };

    const modalRef = this.modalService.open(SearchPopupComponent, option);
    modalRef.componentInstance.tmpl = tmpl;
  }
  closeDialog(): void {
    this.modalService.dismissAll();
  }
}
