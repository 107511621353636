<div class="designerCard" *ngIf="designer">
  <a
    class="designerCard-url"
    [routerLink]="['../products']"
    [queryParams]="{
      designer: designer?._id
    }"
    [attr.title]="designer?.name"
  ></a>
  <div class="designerCard-header">
    <app-image [attr.alt]="designer?.name" [src]="designer.image"></app-image>
  </div>
  <div class="designerCard-body">
    <div class="d-flex align-items-center justify-content-center flex-column">
      <a
        [routerLink]="['../products']"
        [queryParams]="{
          designer: designer?._id
        }"
      >
        <h4 class="fs-xs mb-xxs">{{ designer?.name }}</h4></a
      >
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <p class="mb-0 me-xs">
        <svg-icon
          src="assets/icons/location.svg"
          class="fs-sm me-xxs"
        ></svg-icon>
        <span
          >{{ designer?.country }}{{ designer?.country ? "-" : " " }}
          {{ designer?.city }}</span
        >
      </p>
      <p class="mb-0" *ngIf="productCount">
        <svg-icon
          src="assets/icons/category.svg"
          class="fs-sm me-xxs"
        ></svg-icon>
        <span>{{ productCount }} {{ "shared.product" | translate }}</span>
      </p>
    </div>
  </div>
</div>
