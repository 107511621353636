import { ChangeDetectorRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CartSidebarService {
  active = false;

  Toggle() {
    this.active = !this.active;
  }

  Open() {
    this.active = true;
  }

  Close() {
    this.active = false;
  }
}
