import { translateReturn } from "@shared/utils/utils.methods";

//#region FUNCTIONS
export const FUNC_SETTINGS = "settings";
//#endregion

//#region Returns
export const RETURNS_SETTINGS = `{
    phone
    email
    facebookAccount
    twitterAccount
    instgramAccount
    pinterestAccount
}`;
//#endregion
