import { FormArray, FormGroup } from "@angular/forms";
import { Category } from "@shared/models/Shared.interfaces";
import { CategoryApiService } from "@shared/services/category/category.api";
import { AttributesToUi } from "@shared/services/product-list/product-list.model";
import { validate } from "graphql";

export function translateReturn(variable: any) {
  if (localStorage.getItem("lang")?.indexOf("ar") != -1) {
    const str = variable;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    return "ar" + str2;
  } else {
    return variable;
  }
}

export function getAllErrors(
  form: FormGroup | FormArray
): { [key: string]: any } | null {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors =
      control instanceof FormGroup || control instanceof FormArray
        ? getAllErrors(control)
        : control?.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any });
  return hasError ? result : null;
}

export function isStringsEqual(value1: string, value2: string): boolean {
  if (value1 === value2) {
    return true;
  }
  return false;
}

export function removeBlankValuesFromKeys(obj: any): any {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function removeQueryParamValueFromUrl(
  values: string,
  id: string
): string {
  const currentParamsValue = values.split(",");
  const index = currentParamsValue.indexOf(id);
  if (index > -1) {
    currentParamsValue.splice(index, 1);
  }
  return currentParamsValue.join(",");
}

export function groupBy(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
