import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./core/components/header/header.component";
import { FooterComponent } from "./core/components/footer/footer.component";
import { SharedModule } from "@shared/shared.module";
import { MobileNavComponent } from "./core/components/mobile-nav/mobile-nav.component";
import { CartSidebarComponent } from "./core/components/cart-sidebar/cart-sidebar.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { GraphQLModule } from "@core/graphql/graphql.module";
import { AuthModule } from "./core/auth/auth.module";
import { NgProgressModule } from "ngx-progressbar";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ImageModule } from "@shared/components/image/image.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MobileNavComponent,
    CartSidebarComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ImageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass: "toast-top-full-width",
      preventDuplicates: true
    }),
    GraphQLModule,
    AuthModule,
    NgProgressModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
