import { Injectable } from "@angular/core";
import { Buyer } from "@core/auth/models/auth.models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BuyerService } from "./buyer.service";

@Injectable({ providedIn: "root" })
export class BuyerFacade {
  constructor(private readonly buyerService: BuyerService) {}

  getBuyerInfo$(): Observable<Buyer> {
    return this.buyerService.getBuyerInfo().pipe(map((res) => res.data.user));
  }

  getUserCart$(): Observable<Buyer> {
    return this.buyerService.getBuyerInfo().pipe(map((res) => res.data.user));
  }
}
