<a
  style="cursor: pointer"
  (click)="navigate(['/product/', product?._id])"
  [style.pointer-events]="product?._id ? 'auto' : 'none'"
  [routerLink]="['/product/', product?._id]"
>
  <div class="productCard">
    <div class="productCard-header">
      <a class="productCard-header-thumb" [attr.title]="product?.name">
        <app-image
          *ngIf="product?.images?.length; else variant_placeholder"
          [attr.alt]="product?.name"
          [src]="
            product.images && product.images.length > 0
              ? product.images[0].path
              : ''
          "
        ></app-image>
      </a>
      <ul class="productCard-header-actions">
        <li
          (click)="$event.stopPropagation()"
          (click)="$event.preventDefault()"
        >
          <ng-container *ngIf="isFavourited; else favTemplate">
            <span (click)="deleteFromFavourites(product._id)">
              <svg-icon src="assets/icons/heart-fill.svg"></svg-icon>
            </span>
          </ng-container>

          <ng-template #favTemplate>
            <span (click)="addToFavorites(product._id)">
              <svg-icon src="assets/icons/heart.svg"></svg-icon>
            </span>
          </ng-template>
        </li>
        <!-- <li>
          <span
            [ngClass]="{ 'is-active': compare }"
            (click)="compare = !compare"
          >
            <ng-container *ngIf="compare; else compareTemplate">
              <svg-icon src="assets/icons/compare.svg"></svg-icon>
            </ng-container>
            <ng-template #compareTemplate>
              <svg-icon src="assets/icons/compare.svg"></svg-icon>
            </ng-template>
          </span>
        </li> -->
        <li
          (click)="$event.stopPropagation()"
          (click)="$event.preventDefault()"
          (click)="addToCart()"
        >
          <span>
            <ng-container *ngIf="cart; else cartTemplate">
              <svg-icon src="assets/icons/cart-fill.svg"></svg-icon>
            </ng-container>
            <ng-template #cartTemplate>
              <svg-icon src="assets/icons/cart.svg"></svg-icon>
            </ng-template>
          </span>
        </li>
      </ul>
      <!-- <div class="productCard-header-badge">Free shipping</div> -->
    </div>
    <div class="productCard-body">
      <h4 class="productCard-title">
        <a
          [routerLink]="['/product', product?._id]"
          [innerHTML]="product?.name || product?.arName"
        >
        </a>
      </h4>
      <div class="d-flex justify-content-between align-items-center">
        <p class="productCard-price mb-0">
          <span
            class="productCard-price-current me-xxs"
            *ngIf="product?.egPrice"
          >
            <ng-container
              *ngIf="
                product?.onSale &&
                  this.currentDate < this.saleEndDate &&
                  this.currentDate > this.saleStartDate;
                else notOnSale
              "
            >
              {{
                calculateStuff(
                  product.salePrice ? product.salePrice : "0",
                  product.images[0]?.egExtraPrice || "0"
                )
              }}
              {{"shared.egp" | translate}}
              <span class="productCard-price-old me-xxs"
                >{{
                  calculateStuff(
                    product.egPrice ? product.egPrice : "0",
                    product.images[0]?.egExtraPrice || "0"
                  )
                }}
                {{"shared.egp" | translate}}
              </span>
            </ng-container>

            <ng-template #notOnSale>
              <ng-container>
                {{
                  calculateStuff(
                    product.egPrice ? product.egPrice : "0",
                    product.images[0]?.egExtraPrice || "0"
                  )
                }}
                {{"shared.egp" | translate}}
              </ng-container>
            </ng-template>
          </span>

          <!-- <span class="productCard-price-discount">8% Off</span> -->
        </p>

        <!-- <span class="productCard-rate">
        <svg-icon src="assets/icons/star.svg" class="me-xxs"></svg-icon>
        <span>4.5 <span class="productCard-rate-num">(15)</span></span>
        <app-star-rate rate="3.5"></app-star-rate>
      </span> -->
        <app-star-rate
          *ngIf="product?.averageRating"
          [rate]="product?.averageRating"
        ></app-star-rate>
      </div>
      <!-- <ul class="productCard-colors">
        <li>
          <span class="productCard-colors-item is-active">
            <app-image src="assets/images/dummy/c/1.jpg"></app-image>
          </span>
        </li>
        <li>
          <span class="productCard-colors-item">
            <app-image src="assets/images/dummy/c/2.jpg"></app-image>
          </span>
        </li>
        <li>
          <span class="productCard-colors-item">
            <app-image src="assets/images/dummy/c/3.jpg"></app-image>
          </span>
        </li>
        <li>
          <span class="productCard-colors-item">
            <app-image src="assets/images/dummy/c/4.jpg"></app-image>
          </span>
        </li>
      </ul> -->
    </div>
  </div>
</a>

<ng-template #variant_placeholder>
  <app-image alt="product"></app-image>
</ng-template>
