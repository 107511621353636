import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Buyer } from "@core/auth/models/auth.models";
import {
  AddressType,
  AreaType,
  CityType
} from "@shared/models/Shared.interfaces";
import { AddressFacade } from "@shared/services/address/address.facade";
import {
  CreateAddressInput,
  UpdateAddressInput
} from "@shared/services/address/address.model";
import { BuyerFacade } from "@shared/services/buyer/buyer.facade";
import {
  getAllErrors,
  removeBlankValuesFromKeys
} from "@shared/utils/utils.methods";
import { Toast, ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

@Component({
  selector: "address-modal",
  templateUrl: "./address-modal.component.html",
  styleUrls: ["./address-modal.component.scss"]
})
export class AddressModalComponent implements OnInit, OnChanges {
  @ViewChild("countrySelect") countrySelectHtml!: ElementRef<HTMLSelectElement>;
  @Input() show = false;
  @Output() hideModal = new EventEmitter<boolean>();
  @Output() addressAdded = new EventEmitter<any>();
  @Input() addressToEdit!: AddressType | null;

  // @Input() countries!: CountryType[] | null;
  // @Input() states!: StateType[] | null;
  // @Input() cities!: CityType[] | null;

  // @Input() countries$!: Observable<CountryType[]>;
  @Input() states$!: Observable<AreaType[]>;
  @Input() cities$!: Observable<CityType[]>;

  form!: FormGroup;
  loading: boolean = false;

  close() {
    this.hideModal.emit(false);
    this.show = false;
    this.form.reset();
    this.addressToEdit = null;
  }
  // you can put this method in a module and reuse it as needed

  constructor(
    private readonly addressFacade: AddressFacade,
    private fb: FormBuilder,
    private readonly buyerFacade: BuyerFacade,
    private readonly toastr: ToastrService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addressToEdit && changes.addressToEdit.currentValue) {
      const address = changes.addressToEdit.currentValue as AddressType;
      this.handleFormInitData(address);
    }
  }

  ngOnInit(): void {
    if (this.form) {
      this.form.reset();
    }
    this.cities$ = this.addressFacade.getCities$();
    this.states$ = this.addressFacade.getStates$();
    this.handleFormInitData(this.addressToEdit as AddressType);
  }

  private handleFormInitData(address: AddressType) {
    this.buyerFacade.getBuyerInfo$().subscribe(
      (res) => {
        this.initForm(this.addressToEdit as AddressType, res);
        if (!this.addressToEdit) {
        } else {
          this.initForm(address, res);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initForm(address?: AddressType, userInfo?: Buyer): void {
    this.form = this.fb.group({
      // country: ["Egypt", Validators.required],
      // fName: [userInfo?.fName, Validators.required],
      // lName: [userInfo?.lName, Validators.required],
      // email: [userInfo?.email, Validators.required],
      // apartment: [
      //   address?.apartment ? address.apartment : "1",
      //   Validators.required
      // ],
      city: [address ? address.city : "", Validators.required],
      cityId: [address ? address.cityId : "", Validators.required],

      // state: [address ? address.state : "State", Validators.required],
      // zipCode: [address ? address.zipCode : 1, Validators.required],
      // address: [address ? address.address : "", Validators.required],
      // building: [address ? address.building : "1", Validators.required],
      // floorNo: [address ? address.floorNo : 1, Validators.required],
      // code: [address ? address.code : "02", Validators.required],
      address: [address ? address?.address : ""],
      phone: [
        address ? address.phone : userInfo?.phone,
        [Validators.required, Validators.pattern("(01)[0-9]{9}")]
      ],
      _id: [address ? address._id : ""]
    });
  }

  // changeCountry(event: any): void {
  //   const country: CountryType = JSON.parse(event.target.value) as CountryType;
  //   this.addressFacade.selectCountry(country);
  //   this.form.patchValue({ countryId: country.countryId });
  // }
  changeCity(event: any): void {
    const city: CityType = JSON.parse(event.target.value) as CityType;
    this.addressFacade.selectState;
    this.addressFacade.selectState(city.areas);
    this.form.patchValue({ city: city.name || city.arName });
    this.form.patchValue({ cityId: city._id });
  }

  changeState(event: any): void {
    const state: AreaType = JSON.parse(event.target.value) as AreaType;
    this.form.patchValue({ state: state.name });
  }

  async submit(): Promise<void> {
    this.loading = true;

    try {
      if (this.addressToEdit) {
        this.editAddress();
      } else {
        this.addAddress();
      }
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.addressAdded.emit(false);
    }
  }

  async addAddress(): Promise<void> {
    const removedBlankedKeys = removeBlankValuesFromKeys(this.form.value);
    const input: CreateAddressInput = {
      addAddressInput: {
        ...removedBlankedKeys
      }
    };
    console.log(input)
    const isAdded = await this.addressFacade.createAddress$(input).toPromise();
    if (isAdded) {
      this.addressAdded.emit(true);
      this.toastr.success("Address added successfully");
      this.loading = false;
      this.form.reset();
      this.close();
    }
  }

  async editAddress(): Promise<void> {
    const postedData: UpdateAddressInput = {
      updateAddressInput: this.form.value
    };
    try {
      const isUpdated = await this.addressFacade
        .updateAddress$(postedData)
        .toPromise();
      if (isUpdated) {
        this.toastr.info("Address updated successfully");

        this.loading = false;
        this.addressAdded.emit(true);
        this.form.reset();
        this.close();
      } else {
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
    }
  }

  get phoneNumber() {
    return this.form.get("phone");
  }

  get zipCode() {
    return this.form.get("zipCode");
  }
  get streetName() {
    return this.form.get("address");
  }
  get building() {
    return this.form.get("building");
  }
  get floorNo() {
    return this.form.get("floorNo");
  }
}
