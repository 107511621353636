import { translateReturn } from "@shared/utils/utils.methods";

//#region FUNCTIONS
export const FUNC_ALL_CATEGORIES = "categories";

//#endregion

//#region INPUTS

//#endregion

//#region  TYPES
//#endregion

//#region Returns

export const FUNC_POPULAR_CATS = "popularCategories";

export const RETURNS_POPULAR_CATS = `{
  ${translateReturn("name")}
  _id
  image
subCategories{
  image
  popular
  ${translateReturn("name")}
_id
}

  }`;

export const RETURNS_ALL_CATS = `{
  _id
  ${translateReturn("name")}
  ${translateReturn("description")}
  image
  banner
  displayOrder
  subCategories{
  _id
  category
  ${translateReturn("name")}
  ${translateReturn("description")}
  image
  slug
  }
  }`;

//#endregion

//#region subCategoryByCategoryId
export const FUNC_SUBCATEGORY_BY_CATEGORYID = "subCategoryByCategoryId";
export const INPUT_SUBCATEGORY_BY_CATEGORYID = "getSubcategoryByCategoryInput";
export const TYPE_SUBCATEGORY_BY_CATEGORYID =
  "GetSubcategoriesByCategoryIdInput!";
export const RETURN_SUBCATEGORY_BY_CATEGORYID = `{
  _id
  ${translateReturn("name")}
  ${translateReturn("description")}
  image
  createdAt
  updatedAt
}`;
//#endregion
