<div class="productsFilter" [class.is-active]="overlay">
  <div class="productsFilter-trigger">
    <button class="btn btn-primary w-100 d-lg-none" (click)="overlay = true">
      <span>{{ "shared.filter" | translate }}</span>
    </button>
  </div>
  <div class="productsFilter-overlay">
    <div class="productsFilter-bg" (click)="overlay = false"></div>
    <div class="productsFilter-container">
      <div class="productsFilter-header">
        <h4 class="fs-sm mb-0">{{ "shared.filter-by" | translate }}</h4>
        <button
          class="btn btn-link text-secondary p-0"
          (click)="resetFilters()"
        >
          {{ "shared.reset" | translate }}
        </button>
      </div>

      <div class="productsFilter-item" *ngIf="subCategories?.length">
        <input
          type="checkbox"
          class="productsFilter-item-input"
          [checked]="checkedSubCategories?.length"
        />
        <div class="productsFilter-item-header">
          <h5 class="fs-xs mb-0">
            {{ "shared.subcategories" | translate }}

            <span
              *ngIf="checkedSubCategories?.length"
              class="px-3 py-2"
              style="background-color: bisque; border-radius: 50%"
            >
              {{ checkedSubCategories?.split(",")?.length }}
            </span>
          </h5>
          <span>{{ subCategories?.length }}</span>
        </div>
        <div
          class="productsFilter-item-body"
          *ngFor="let item of subCategories"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              [attr.id]="item._id"
              [checked]="checkSelectedSubCategories(item._id)"
              (change)="checkClick(item, $event)"
            />
            <label class="form-check-label" for="Sofas">
              {{ item.name || item.arName }}
            </label>
          </div>
        </div>
      </div>

      <div
        class="productsFilter-item"
        *ngFor="let item of attributesFilter; let i = index"
      >
        <input
          type="checkbox"
          class="productsFilter-item-input"
          [checked]="item.noOfSelected[i]"
        />

        <div class="productsFilter-item-header">
          <h5 class="fs-xs mb-0">
            {{ item.property }}
            <span
              *ngIf="item.noOfSelected[i]"
              class="px-3 py-2"
              style="background-color: bisque; border-radius: 50%"
            >
              {{ item.noOfSelected[i] }}
            </span>
          </h5>

          <span>{{ item.values.length }}</span>
        </div>
        <div class="productsFilter-item-body">
          <div
            class="form-check"
            *ngFor="let filter of item.values; let i = index"
          >
            <input
              (change)="
                attributeFilterCheckClick(
                  {
                    property: item.sentProperty,
                    value: item.sentValues[i].value
                  },
                  $event
                )
              "
              [checked]="filter.selected"
              class="form-check-input"
              type="checkbox"
              [value]=""
              [attr.id]="filter"
              #test
            />
            <label class="form-check-label" for="Sofas">
              {{ filter.value }}
            </label>
          </div>
        </div>
      </div>

      <!-- Categories of search result only -->
      <div
        class="productsFilter-item"
        *ngIf="categories && categories.length > 0"
      >
        <input
          type="checkbox"
          class="productsFilter-item-input"
          [checked]="noOfSelectedCategories > 0"
        />
        <div class="productsFilter-item-header">
          <h5 class="fs-xs mb-0">
            {{ "shared.categories" | translate }}
            <span
              *ngIf="noOfSelectedCategories > 0"
              class="px-3 py-2"
              style="background-color: bisque; border-radius: 50%"
            >
              {{ noOfSelectedCategories }}
            </span>
          </h5>
          <span>{{ categories.length }}</span>
        </div>
        <div class="productsFilter-item-body">
          <div class="form-check" *ngFor="let filter of categories">
            <input
              class="form-check-input"
              type="checkbox"
              [value]="filter"
              [attr.id]="filter"
              [checked]="checkSelectedCategories(filter)"
              (change)="categoryOfSearchFilter($event)"
              #categoriesCheckboxes
            />
            <label class="form-check-label" [innerHTML]="filter">
              <!-- {{ filter }} -->
            </label>
          </div>
        </div>
      </div>

      <div
        class="productsFilter-item"
        *ngIf="designers && designers.length > 0"
      >
        <input
          type="checkbox"
          class="productsFilter-item-input"
          [checked]="noOfSelectedDesigners > 0"
        />
        <div class="productsFilter-item-header">
          <h5 class="fs-xs mb-0">
            {{ "designer.designers" | translate }}
            <span
              *ngIf="noOfSelectedDesigners > 0"
              class="px-3 py-2"
              style="background-color: bisque; border-radius: 50%"
            >
              {{ noOfSelectedDesigners }}
            </span>
          </h5>
          <span>{{ designers.length }}</span>
        </div>
        <div class="productsFilter-item-body">
          <div class="form-check" *ngFor="let filter of designers">
            <input
              (change)="designerSearchFilter($event)"
              class="form-check-input"
              type="checkbox"
              [value]="filter"
              [attr.id]="filter"
              [checked]="checkSelectedDesigners(filter)"
            />
            <label class="form-check-label" for="Sofas">
              {{ filter }}
            </label>
          </div>
        </div>
      </div>
      <div class="productsFilter-item" *ngIf="showPriceRange">
        <input type="checkbox" class="productsFilter-item-input" checked />
        <div class="productsFilter-item-header">
          <h5 class="fs-xs mb-0">{{ "shared.price" | translate }}</h5>
        </div>
        <div class="productsFilter-item-body">
          <ngx-slider
            (userChangeEnd)="changePrice($event)"
            [(value)]="selectedPriceRange.fromPrice"
            [(highValue)]="selectedPriceRange.toPrice"
            [options]="priceOptions"
          ></ngx-slider>
        </div>
      </div>
    </div>
  </div>
</div>
