import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AngularSvgIconModule } from "angular-svg-icon";
import { ProductCardComponent } from "./components/product-card/product-card.component";
import { ProductsFilterComponent } from "./components/products-filter/products-filter.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { StarRateComponent } from "./components/star-rate/star-rate.component";
import { DesignerCardComponent } from "./components/designer-card/designer-card.component";
import { PostCardComponent } from "./components/post-card/post-card.component";
import { ShareComponent } from "./components/share/share.component";
import { CategoryCardComponent } from "./components/category-card/category-card.component";
import { AddressModalComponent } from "./components/address-modal/address-modal.component";
import { PlaceholderComponent } from "./components/placeholder/placeholder.component";
import { ButtonLoaderDirective } from "./directives/buttonLoader.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ValidationErrorMessageComponent } from "./components/validation-error-message/validation-error-message.component";
import { EmptyListComponent } from "./components/empty-list/empty-list.component";
import { ReversePipe } from "./pipes/reverseArray.pipe";
import { ReceiptComponent } from "./components/receipt/receipt.component";
import { RegisterComponent } from "./components/register/register.component";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { SearchPopupComponent } from "./components/search-popup/search-popup.component";
import { ClickOutsideModule } from "ng-click-outside";
import { AutofocusDirective } from "./directives/myAutoFocus.directive";
import { ImageModule } from "./components/image/image.module";

@NgModule({
  declarations: [
    ProductCardComponent,
    ProductsFilterComponent,
    StarRateComponent,
    DesignerCardComponent,
    PostCardComponent,
    ShareComponent,
    CategoryCardComponent,
    AddressModalComponent,
    PlaceholderComponent,
    ButtonLoaderDirective,
    ValidationErrorMessageComponent,
    EmptyListComponent,
    ReversePipe,
    ReceiptComponent,
    RegisterComponent,
    AddressFormComponent,
    SearchPopupComponent,
    AutofocusDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    RouterModule,
    NgxSliderModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ClickOutsideModule,
    ImageModule
  ],
  exports: [
    HttpClientModule,
    AngularSvgIconModule,
    ProductCardComponent,
    ProductsFilterComponent,
    NgxSliderModule,
    StarRateComponent,
    DesignerCardComponent,
    PostCardComponent,
    ShareComponent,
    CategoryCardComponent,
    AddressModalComponent,
    PlaceholderComponent,
    ButtonLoaderDirective,
    AutofocusDirective,
    TranslateModule,
    ValidationErrorMessageComponent,
    EmptyListComponent,
    ReversePipe,
    FormsModule,
    ReactiveFormsModule,
    ReceiptComponent,
    RegisterComponent,
    AddressFormComponent,
    SearchPopupComponent
  ]
})
export class SharedModule {}
