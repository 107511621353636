<form *ngIf="form" [formGroup]="form">
  <div class="mb-xs">
    <label class="form-label">{{ "account.phone" | translate }}</label>
    <input formControlName="phone" class="form-control" type="text" />

    <ng-container *ngIf="phoneNumber?.dirty || phoneNumber?.touched">
      <div *ngIf="phoneNumber?.invalid" class="text-danger">
        <div *ngIf="phoneNumber?.errors?.required">This field is required.</div>
        <div *ngIf="phoneNumber?.errors?.pattern">Invalid phone number.</div>
      </div>
    </ng-container>
  </div>

  <div class="mb-xs">
    <label class="form-label">City</label>
    <select class="form-select" (change)="changeCity($event)">
      <option disabled selected="selected">--Select--</option>
      <option [value]="item | json" *ngFor="let item of cities$ | async">
        {{ item.name || item.arName }}
      </option>
    </select>
  </div>

  <!-- Street name -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.addressDetails" | translate }}</label>
    <textarea
      formControlName="address"
      class="form-control"
      rows="5"
    ></textarea>

    <ng-container *ngIf="streetName?.dirty || streetName?.touched">
      <div *ngIf="streetName?.invalid" class="text-danger">
        <div *ngIf="streetName?.errors?.required">This field is required.</div>
      </div>
    </ng-container>
  </div>
  <!--  -->

  <button
    type="button"
    class="btn btn-primary me-xs pe-md ps-md"
    [disabled]="form.invalid"
    (click)="submit()"
    buttonLoader
    [loadingState]="loading"
  >
    {{ "shared.save" | translate }}
  </button>
</form>
