<form [formGroup]="signUpForm" (ngSubmit)="signUp()" novalidate>
  <!-- First name -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.firstName" | translate }} *</label>
    <input
      formControlName="fName"
      type="text"
      class="form-control form-control-lg"
    />
  </div>
  <app-validation-error-message
    [formGroup]="signUpForm"
    [controlName]="'fName'"
    (formChange)="($event)"
  >
  </app-validation-error-message>
  <!--  -->

  <!-- Last name -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.lastName" | translate }} *</label>
    <input
      formControlName="lName"
      type="text"
      class="form-control form-control-lg"
    />
  </div>

  <app-validation-error-message
    [formGroup]="signUpForm"
    [controlName]="'lName'"
    (formChange)="($event)"
  >
  </app-validation-error-message>
  <!--  -->

  <!-- Email -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.emailAddress" | translate }} </label>
    <input
      type="email"
      formControlName="email"
      class="form-control form-control-lg"
      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
    />
    <app-validation-error-message
      [formGroup]="signUpForm"
      [controlName]="'email'"
      (formChange)="($event)"
    >
    </app-validation-error-message>
  </div>
  <!--  -->

  <!-- Phone -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.mobileNumber" | translate }} </label>
    <input
      autocomplete="new-phone"
      type="text"
      formControlName="phone"
      class="form-control form-control-lg"
    />
    <app-validation-error-message
      [formGroup]="signUpForm"
      [controlName]="'phone'"
      (formChange)="($event)"
    >
    </app-validation-error-message>
  </div>
  <!--  -->

  <!-- Password -->
  <div class="mb-xs">
    <label class="form-label">{{ "shared.password" | translate }} *</label>
    <input
      autocomplete="new-password"
      type="password"
      formControlName="password"
      minlength="6"
      maxlength="20"
      class="form-control form-control-lg"
    />
    <app-validation-error-message
      [formGroup]="signUpForm"
      [controlName]="'password'"
      (formChange)="($event)"
    >
    </app-validation-error-message>
  </div>
  <!-- buttonLoader
    [loadingState]="loading" -->
  <button [disabled]="signUpForm.invalid" class="btn btn-primary btn-lg w-100">
    {{ "auth.signUp" | translate }}
  </button>
  <!-- <div class="text-center mt-md">
    <p class="mt-xxs">
      <span> {{ "auth.alreadyHaveAccount" | translate }} </span>
      <a [routerLink]="['/auth/login']" title="Login" class="fw-bold">{{
        "shared.login" | translate
      }}</a>
    </p>
  </div> -->
</form>
