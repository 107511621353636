import { translateReturn } from "@shared/utils/utils.methods";

//#region favourite
export const FUNC_FAVOURITE = "favourite";
export const TYPE_FAVOURITE = "GetFavouriteInput!";
export const INPUT_FAVOURITE = "getFavouriteInput";
export const RETURN_FAVOURITE = `{
  variant{
    product{
      ${translateReturn("name")}
    }
  }
}`;
//#endregion

//#region favourites
export const FUNC_FAVOURITES = "favorites";
export const RETURN_FAVOURITES = `{
  products{
    _id
    ${translateReturn("name")}
  egPrice
  salePrice
  onSale
  saleStartDate
  saleEndDate
  images{
     primaryImage
     _id
    path
    egExtraPrice
  }
  }
  bundles{
    _id
    ${translateReturn("name")}
    price
    originalPrice
    mainImage
  }


}`;
//#endregion

//#region addToFavourite
export const FUNC_ADD_TO_FAVOURITE = "addFavorite";
export const TYPE_ADD_TO_FAVOURITE = "AddRemoveFavoriteInput!";
export const INPUT_ADD_TO_FAVOURITE = "addRemoveFavoriteInput";
export const RETURN_ADD_TO_FAVOURITE = `{
  message
}`;
//#endregion

//#region deleteFavourite
export const FUNC_DELETE_FAVOUTRITE = "removeFavorite";
export const TYPE_DELETE_FAVOUTRITE = "AddRemoveFavoriteInput!";
export const INPUT_DELETE_FAVOUTRITE = "addRemoveFavoriteInput";
export const RETURN_DELETE_FAVOUTRITE = `{
  message
}`;
//#endregion
