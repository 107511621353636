import { translateReturn } from "@shared/utils/utils.methods";

//#region getUserAddresses
export const FUNC_GET_USER_ADDRESSES = "getUserAddresses";
export const RETURN_USER_ADDRESSES = `{
  addressId
  streetName
  zipCode
  buildingNo
  floorNo
  phoneNo

  buyer{
    buyerId
  }

  country{
    countryId
    ${translateReturn("name")}

    states{
      stateId
      ${translateReturn("name")}

      cities{
        cityId
        ${translateReturn("name")}

      }
    }
    deliveryDays
    additionalShippingPercent
  }
  city{
    cityId
    ${translateReturn("name")}

  }
  state{
    stateId
    ${translateReturn("name")}
  }
}`;
//#endregion

//#region getCountries
export const FUNC_GET_COUNTRIES = "countries";
export const RETURN_GET_COUNTRIES = `{
  countryId
  ${translateReturn("name")}

    states{
      stateId
      ${translateReturn("name")}

      cities{
        cityId
        ${translateReturn("name")}
      }
    }
}`;

//#endregion

//#region getCities
export const FUNC_GET_CITIES = "cities";
export const RETURN_GET_CITIES = `{
  _id
  ${translateReturn("name")}



}`;
// // areas{
//   ${translateReturn("name")}

// }

//#endregion

//#region createAddress
export const FUNC_CREATE_ADDRESS = "AddAddress";
export const TYPE_CREATE_ADDRESS = "AddAddressInput!";
export const INPUT_CREATE_ADDRESS = "addAddressInput";
export const RETURN_CREATE_ADDRESS = `{
  _id
  city
  cityId
  phone
  address
}`;
//#endregion

//#region updateAddress
export const FUNC_UPDATE_ADDRESS = "updateAddress";
export const TYPE_UPDATE_ADDRESS = "UpdateAddressInput!";
export const INPUT_UPDATE_ADDRESS = "updateAddressInput";
export const RETURN_UPDATE_ADDRESS = `{
  _id
  city
  cityId
  phone
  address
}`;
//#endregion
//#region deleteAddress
export const FUNC_DELETE_ADDRESS = "deleteAddress";
export const TYPE_DELETE_ADDRESS = "DeleteAddressInput!";
export const INPUT_DELETE_ADDRESS = "deleteAddressInput";
export const RETURN_DELETE_ADDRESS = `{
  message
}`;
//#endregion
