import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Setting } from "src/app/pages/home/model/home.model";
import { FooterFacade } from "./service/footer.facade";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  setting$!: Observable<Setting>;
  currentYear: number = new Date().getFullYear();

  constructor(private footerFacade: FooterFacade) {
    this.setting$ = this.footerFacade.getSetting$();
  }

  ngOnInit(): void {}
}
