<div class="rate">
  <div class="rate-colored" [style.width.%]="(rate * 100) / 5">
    <svg-icon src="assets/icons/star.svg" (click)="setRate(1)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(2)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(3)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(4)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(5)"></svg-icon>
  </div>
  <div class="rate-uncolored">
    <svg-icon src="assets/icons/star.svg" (click)="setRate(1)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(2)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(3)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(4)"></svg-icon>
    <svg-icon src="assets/icons/star.svg" (click)="setRate(5)"></svg-icon>
  </div>
</div>
