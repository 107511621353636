import { Injectable } from "@angular/core";
import {
  AddressType,
  AreaType,
  CityType
} from "@shared/models/Shared.interfaces";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AddressService } from "./address.service";
import {
  CreateAddressInput,
  DeleteAddressInput,
  UpdateAddressInput
} from "./address.model";

@Injectable({ providedIn: "root" })
export class AddressFacade {
  private _statesSubject: Subject<AreaType[]> = new Subject();
  private _citiesSubject: Subject<CityType[]> = new Subject();
  constructor(private addressService: AddressService) {}

  getUserAddresses$(): Observable<AddressType[]> {
    return this.addressService
      .getAdresses()
      .pipe(map((x) => x.data.getUserAddresses));
  }

  // getCountries$(): Observable<CountryType[]> {
  //   return this.addressService.getCoutries().pipe(map((x) => x.data.countries));
  // }

  getCities$(): Observable<CityType[]> {
    return this.addressService.getCities().pipe(map((x) => x.data.cities));
  }

  createAddress$(
    createAddressInput: CreateAddressInput
  ): Observable<AddressType> {
    return this.addressService.addAdress(createAddressInput).pipe(
      map((x) => {
        return x.data.AddAddress;
      })
    );
  }

  updateAddress$(
    updateAddressInput: UpdateAddressInput
  ): Observable<AddressType> {
    return this.addressService
      .updateAddress(updateAddressInput)
      .pipe(map((x) => x.data.updateAddress));
  }
  deleteAddress$(deleteAddressInput: DeleteAddressInput): Observable<string> {
    return this.addressService
      .deleteAddress(deleteAddressInput)
      .pipe(map((x) => x.data.deleteAddress.message));
  }

  // selectCountry(country: CountryType): void {
  //   this._statesSubject.next(country.states);
  //   this.selectState(country?.states[0]);
  // }

  selectState(states: AreaType[]): void {
    this._statesSubject.next(states);
  }

  getStates$(): Observable<AreaType[]> {
    return this._statesSubject.asObservable();
  }

  // getCities$(): Observable<CityType[]> {
  //   return this._citiesSubject.asObservable();
  // }
}
