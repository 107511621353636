import { inject, NgModule } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloLink, DefaultOptions, InMemoryCache } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { environment } from "../../../environments/environment";
import { setContext } from "@apollo/client/link/context";
import { onError } from "apollo-link-error";

import { HandleErrorsFacade } from "@core/facades/handle-errors.facade";

const uri = environment.url;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.log(extensions?.response?.statusCode);
      if (extensions?.response?.statusCode == 403) {
        return HandleErrorsFacade?.validationError(
          "You should login to complete this action"
        );
      } else {
        return HandleErrorsFacade?.validationError(message);
      }
    });
  }
  if (networkError) {
    HandleErrorsFacade?.serverError(
      "Server Error Occurred, we trying to solve it now =  " +
        networkError?.message
    );
  }
});

export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: "charset=utf-8"
    }
  }));

  const auth = setContext((operation, context) => {
    const token = localStorage.getItem("AUTH_TOKEN");

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: "Bearer " + JSON.parse(token)
        }
      };
    }
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "none"
    }
  };
  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  // const link = ApolloLink.from([basic, httpLink.create({ uri, auth })]);
  const cache = new InMemoryCache();

  return {
    link: errorLink.concat(link as any),
    cache,
    defaultOptions: defaultOptions
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
