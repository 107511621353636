import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import { SubCategory } from "@shared/models/Shared.interfaces";
import { ActivatedRoute, Router } from "@angular/router";
import { AttributesFilter } from "@shared/services/product-list/product-list.model";

@Component({
  selector: "app-products-filter",
  templateUrl: "./products-filter.component.html",
  styleUrls: ["./products-filter.component.scss"]
})
export class ProductsFilterComponent implements OnInit, OnChanges {
  @Output() checkClicked = new EventEmitter<any>();
  @Output() AttributeFiltercheckClicked = new EventEmitter<any>();
  @Output() priceChanged = new EventEmitter<any>();
  @Output() resetFiltersClicked = new EventEmitter<boolean>();
  @Output() categoryOfSearchFilterClicked = new EventEmitter<any>();
  @Output() designerFilterClicked = new EventEmitter<any>();
  @Input() attributesFilter!: AttributesFilter[];
  @Input() subCategories!: SubCategory[] | undefined;
  @Input() checkedSubCategories!: string | null;
  @Input() checkedCategories!: string | null;
  @Input() checkedDesigners!: string | null;
  @Input() showPriceRange: boolean = true;

  // For Search filter only
  @Input() categories!: string[] | undefined;
  @Input() designers!: string[] | undefined;
  // For Search filter only

  @ViewChildren("categoriesCheckboxes")
  categoriesCheckboxes!: QueryList<any>;
  @Input() set selectedPriceRangeInput(myProperty: any) {
    if (myProperty) {
      this.selectedPriceRange = myProperty;
    }
  }
  overlay = false;
  value = 100;
  @Input() priceOptions: Options = {
    floor: 0,
    ceil: 0,
    disabled: false
  };

  selectedPriceRange = {
    fromPrice: 0,
    toPrice: 0
  };

  @Input() priceValue!: number;
  @Input() highValue!: number;
  noOfSelectedCategories: number = 0;
  noOfSelectedDesigners: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checkedCategories) {
      if (changes.checkedCategories.currentValue) {
        this.noOfSelectedCategories =
          changes.checkedCategories.currentValue.split(",").length;
      } else {
        this.noOfSelectedCategories = 0;
      }
    }

    if (changes.checkedDesigners) {
      if (changes.checkedDesigners.currentValue) {
        this.noOfSelectedDesigners =
          changes.checkedDesigners.currentValue.split(",").length;
      } else {
        this.noOfSelectedDesigners = 0;
      }
    }
  }

  ngOnInit(): void {}
  resetFilters() {
    this.resetFiltersClicked.next(true);
  }

  attributeFilterCheckClick(value: any, event: any) {
    if (event.target.checked) {
      this.AttributeFiltercheckClicked.emit({ value, checkedBefore: false });
    } else {
      this.AttributeFiltercheckClicked.emit({ value, checkedBefore: true });
    }
  }
  checkClick(value: any, event: any) {
    if (event.target.checked) {
      this.checkClicked.emit({ value, checkedBefore: false });
    } else {
      this.checkClicked.emit({ value, checkedBefore: true });
    }
  }

  checkSelectedSubCategories(subCatid: string): boolean {
    if (this.checkedSubCategories) {
      return this.checkedSubCategories.split(",").includes(subCatid);
    }
    return false;
  }

  // checkSelectedAttributes(subCatid: string): boolean {
  //   if (this.checkedSubCategories) {
  //     return this.checkedSubCategories.split(",").includes(subCatid);
  //   }
  //   return false;
  // }

  checkSelectedCategories(catsIds: string): boolean {
    if (this.checkedCategories) {
      return this.checkedCategories.split(",").includes(catsIds);
    }
    return false;
  }

  checkSelectedDesigners(designerId: string): boolean {
    if (this.checkedDesigners) {
      return this.checkedDesigners.split(",").includes(designerId);
    }
    return false;
  }

  changePrice(e: any): void {
    this.priceChanged.next(e);
  }
  categoryOfSearchFilter(event?: any) {
    this.categoryOfSearchFilterClicked.emit(event);
  }

  designerSearchFilter(event?: any) {
    this.designerFilterClicked.emit(event);
  }
}
