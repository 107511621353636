import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { Observable, throwServerError } from "@apollo/client/core";
import { FavouriteFacade } from "@shared/services/favourite/favourite.facade";
import {
  AddToFavouriteInput,
  DeleteFavouriteInput
} from "@shared/services/favourite/favourite.model";
import { take } from "rxjs/operators";
import { ProductType } from "@shared/services/product/product.model";
import { Router } from "@angular/router";
import { CartFacade } from "@core/cart/services/cart.facade";
import { from } from "rxjs";
import { CartSidebarService } from "@core/services/cart-sidebar/cart-sidebar.service";
import { ToastrService } from "ngx-toastr";
import { AddToCartInputInterface } from "@core/cart/model/cart.models";
import { SSL_OP_NO_TLSv1_1 } from "constants";

@Component({
  selector: "product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"]
})
export class ProductCardComponent implements OnInit, OnChanges {
  isFavourited = false;
  cart = false;
  compare = false;
  saleStartDate!: number;
  saleEndDate!: number;
  currentDate!: number;

  @Input() product!: ProductType;
  constructor(
    private readonly favouriteFacade: FavouriteFacade,
    private ngZone: NgZone,
    private router: Router,
    private cdk: ChangeDetectorRef,
    private cartFacade: CartFacade,
    private cartSideService: CartSidebarService,
    private toastr: ToastrService
  ) {}

  async addToCart() {
    try {
      if (this.product && this.product.images) {
        const addToCartInputInterface: AddToCartInputInterface = {
          modelId: this.product.images[0]._id,
          qty: 1,
          productId: this.product._id
        };
        const added = await this.cartFacade.addToCart(addToCartInputInterface);
        if (added) {
          this.ngZone.run(() => this.cartSideService.Toggle());
        }
      }
    } catch (error) {
      this.ngZone.run(() => this.toastr.error(error));
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product.currentValue) {
      if (
        this.product &&
        this.product.onSale &&
        this.product.saleEndDate &&
        this.product.saleStartDate
      ) {
        this.saleStartDate = Date.parse(
          this.product.saleStartDate?.toString() as string
        );
        this.saleEndDate = Date.parse(
          this.product.saleEndDate?.toString() as string
        );
        this.currentDate = new Date().getTime();
      }

      if (this.favouriteFacade.checkIfProductFavourited(this.product._id)) {
        this.isFavourited = true;
      }
    }
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  ngOnInit(): void {}

  addToFavorites(productId: string): void {
    const input: AddToFavouriteInput = {
      addRemoveFavoriteInput: { productId }
    };
    this.ngZone.run(() => {
      this.favouriteFacade.addFavorite(input).subscribe(
        (data) => {
          if (data) {
            this.isFavourited = true;
            this.cdk.detectChanges();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  deleteFromFavourites(productId: string): void {
    const input: AddToFavouriteInput = {
      addRemoveFavoriteInput: { productId }
    };

    this.favouriteFacade.deleteFavourite(input).subscribe((data) => {
      if (data) {
        this.isFavourited = false;
        this.cdk.detectChanges();
      }
    });
  }

  calculateStuff(somevalue1: string | number, somevalue2: string) {
    return Number(somevalue1) + Number(somevalue2);
  }
}
