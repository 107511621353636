<div class="theFooter">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-6 theFooter-col">
        <h4 class="mb-sm fs-sm">{{ "footer.aboutKemitt" | translate }}</h4>
        <ul class="theFooter-nav">
          <li>
            <a [routerLink]="['/blog']" title="Blog"
              >{{ "footer.blog" | translate }}
            </a>
          </li>
          <li>
            <a [routerLink]="['/news']" title="News">{{
              "footer.news" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/careers']" title="Careers">{{
              "footer.careers" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/about']" title="About us">{{
              "footer.aboutUs" | translate
            }}</a>
          </li>
          <!-- <li><a [routerLink]="['/gift']" title="Gift Cards">Gift Cards</a></li> -->
          <li>
            <a [routerLink]="['/how']" title="How it works "
              >{{ "footer.howItWork" | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-6 theFooter-col">
        <h4 class="mb-sm fs-sm">{{ "footer.features" | translate }}</h4>
        <ul class="theFooter-nav">
          <li>
            <a [routerLink]="['/']" title="Deals">{{
              "footer.deals" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/all-categories']" title="Products">{{
              "footer.products" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/designers-area']" title="Designer’s Area">
              {{ "footer.designer-arena" | translate }}
            </a>
          </li>

          <li>
            <a [routerLink]="['/designers']" title="Designer’s Area">
              {{ "footer.discover-designers" | translate }}
            </a>
          </li>
          <!--
          <li>
            <a [routerLink]="['/']" title="Custom Design">Custom Design</a>
          </li> -->
          <!-- <li>
            <a [routerLink]="['/']" title="Site Map">{{
              "footer.siteMap" | translate
            }}</a>
          </li> -->
        </ul>
      </div>
      <div class="col-lg-3 col-6 theFooter-col">
        <h4 class="mb-sm fs-sm">{{ "footer.customers" | translate }}</h4>
        <ul class="theFooter-nav">
          <li>
            <a [routerLink]="['/faq']" title="FAQ">{{
              "footer.FAQ" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/terms']" title="Terms">{{
              "footer.terms" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/contact']" title="Contact">
              {{ "contactUs.contact" | translate }}</a
            >
          </li>
          <li>
            <a [routerLink]="['/privacy']" title="Privacy policy">
              {{ "footer.privacyPolicy" | translate }}</a
            >
          </li>

          <!-- <li>
            <a [routerLink]="['/']" title="Return Policy">Return Policy</a>
          </li> -->
        </ul>
      </div>
      <div
        class="col-lg-3 col-6 theFooter-col"
        *ngIf="setting$ | async as setting"
      >
        <h4 class="mb-sm fs-sm">{{ "footer.contact" | translate }}</h4>
        <p>
          {{ "footer.phone" | translate }}

          <a href="tel:{{ setting.phone }}" [title]="setting.phone">{{
            setting.phone
          }}</a>
        </p>
        <p>
          {{ "footer.email" | translate }}

          <a href="mailto:{{ setting.email }}" [title]="setting.email">{{
            setting.email
          }}</a>
        </p>
        <div class="d-flex align-items-center">
          <a
            class="d-inline-block me-xs fs-sm"
            [href]="setting.facebookAccount"
            target="_blank"
            title="facebook"
          >
            <svg-icon src="assets/icons/facebook.svg"></svg-icon>
          </a>
          <a
            class="d-inline-block me-xs fs-sm"
            [href]="setting.twitterAccount"
            target="_blank"
            title="twitter"
          >
            <svg-icon src="assets/icons/twitter.svg"></svg-icon>
          </a>
          <a
            class="d-inline-block me-xs fs-sm"
            [href]="setting.instgramAccount"
            target="_blank"
            title="instagram"
          >
            <svg-icon src="assets/icons/instagram.svg"></svg-icon>
          </a>
          <a
            class="d-inline-block me-xs fs-sm"
            [href]="setting.pinterestAccount"
            target="_blank"
            title="pinterest"
          >
            <svg-icon src="assets/icons/pinterest.svg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="theFooter-end text-center">
      <span class="me-xs">
        {{ "footer.copy-rights" | translate }}
      </span>
      <span>
        {{ "footer.made-with" | translate }}
        <svg-icon
          src="assets/icons/heart-fill.svg"
          class="text-secondary"
        ></svg-icon>
        {{ "footer.at" | translate }}

        <a href="https://www.baianat.com/" title="Baianat" target="_blank">
          {{ "footer.baianat" | translate }}
        </a>
      </span>
    </div>
  </div>
</div>
