<div class="c-share">
  <input class="c-share__input" type="checkbox" id="checkbox">
  <label class="c-share__toggler" for="checkbox">
    <span class="c-share__icon"></span>
  </label>

  <ul class="c-share_options" data-title="Share">
    <li>Facebook</li>
    <li>Twitter</li>
    <li>Google</li>
    <li>Email</li>
  </ul>
</div>
