<div
  class="modal fade show d-block"
  tabindex="-1"
  *ngIf="show"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="fs-md me-xs mb-md">
            {{ "shared.addressDetails" | translate }}
          </h4>
          <button class="btn btn-link mb-md p-0" (click)="close()">
            <svg-icon src="assets/icons/close.svg" class="fs-md"></svg-icon>
          </button>
        </div>
        <form *ngIf="form" [formGroup]="form">
          <!-- Country -->
          <!-- <div class="mb-xs">
            <label class="form-label">Country</label>
            <select
              (change)="changeCountry($event)"
              #countrySelect
              class="form-select"
            >
              <option disabled selected="selected" *ngIf="!addressToEdit">
                --Select--
              </option>
              <option
                [selected]="
                  addressToEdit
                    ? addressToEdit.country.countryId == item.countryId
                    : false
                "
                [value]="item | json"
                *ngFor="let item of countries$ | async"
              >
                {{ item.name || item.arName }}
              </option>
            </select>
          </div> -->
          <!--  -->
          <!-- City -->

          <!-- info -->
          <!-- <div class="mb-xs row">
            <div class="col-6">
              <label class="form-label">First name</label>
              <input class="form-control" formControlName="fName" type="text" />
            </div>
            <div class="col-6">
              <label class="form-label">Last name</label>
              <input class="form-control" formControlName="lName" type="text" />
            </div>
          </div> -->

          <!-- Phone no -->
          <div class="mb-xs">
            <label class="form-label">{{ "account.phone" | translate }}</label>
            <input formControlName="phone" class="form-control" type="text" />

            <ng-container *ngIf="phoneNumber?.dirty || phoneNumber?.touched">
              <div *ngIf="phoneNumber?.invalid" class="text-danger">
                <div *ngIf="phoneNumber?.errors?.required">
                  This field is required.
                </div>
                <div *ngIf="phoneNumber?.errors?.pattern">
                  Invalid phone number.
                </div>
              </div>
            </ng-container>
          </div>
          <!--  -->

          <!-- Phone no -->
          <!-- <div class="mb-xs">
            <label class="form-label">Email</label>
            <input formControlName="email" class="form-control" type="email" />
          </div> -->
          <!--  -->

          <!--  -->

          <div class="mb-xs">
            <label class="form-label">{{ "shared.city" | translate }}</label>
            <select class="form-select" (change)="changeCity($event)">
              <option disabled selected="selected" *ngIf="!addressToEdit">
                --{{ "shared.select" | translate }}--
              </option>
              <option
                [selected]="
                  addressToEdit ? addressToEdit.city == item.name : false
                "
                [value]="item | json"
                *ngFor="let item of cities$ | async"
              >
                {{ item.name || item.arName }}
              </option>
            </select>
          </div>
          <!--  -->

          <!-- State -->
          <!-- <div class="mb-xs">
            <label class="form-label">State</label>
            <select class="form-select" (change)="changeState($event)">
              <option disabled selected="selected" *ngIf="!addressToEdit">
                --Select--
              </option>
              <option
                [selected]="
                  addressToEdit ? addressToEdit.state == item.name : false
                "
                [value]="item | json"
                *ngFor="let item of states$ | async"
              >
                {{ item.name }}
              </option>
            </select>
          </div> -->
          <!--  -->

          <!-- Zip code -->
          <!-- <div class="mb-xs">
            <label class="form-label">Zip Code</label>
            <input
              formControlName="zipCode"
              class="form-control"
              type="number"
            />

            <ng-container *ngIf="zipCode?.dirty || zipCode?.touched">
              <div *ngIf="zipCode?.invalid" class="text-danger">
                <div *ngIf="zipCode?.errors?.required">
                  This field is required.
                </div>
              </div>
            </ng-container>
          </div> -->
          <!--  -->

          <!-- Street name -->
          <div class="mb-xs">
            <label class="form-label">{{
              "shared.addressDetails" | translate
            }}</label>
            <textarea
              formControlName="address"
              class="form-control"
              rows="5"
            ></textarea>

            <ng-container *ngIf="streetName?.dirty || streetName?.touched">
              <div *ngIf="streetName?.invalid" class="text-danger">
                <div *ngIf="streetName?.errors?.required">
                  This field is required.
                </div>
              </div>
            </ng-container>
          </div>
          <!--  -->

          <!-- building -->
          <!-- <div class="mb-xs">
            <label class="form-label">Building Name / Number</label>
            <input
              formControlName="building"
              class="form-control"
              type="text"
            />

            <ng-container *ngIf="building?.dirty || building?.touched">
              <div *ngIf="building?.invalid" class="text-danger">
                <div *ngIf="building?.errors?.required">
                  This field is required.
                </div>
              </div>
            </ng-container>
          </div> -->
          <!--  -->

          <!-- Floor number -->
          <!-- <div class="mb-xs">
            <label class="form-label">Floor Number</label>
            <input
              formControlName="floorNo"
              class="form-control"
              type="number"
            />

            <ng-container *ngIf="floorNo?.dirty || floorNo?.touched">
              <div *ngIf="floorNo?.invalid" class="text-danger">
                <div *ngIf="floorNo?.errors?.required">
                  This field is required.
                </div>
              </div>
            </ng-container>
          </div> -->
          <!--  -->

          <!-- <div class="mb-xs">
            <label class="form-label">apartment</label>
            <input
              formControlName="apartment"
              class="form-control"
              type="text"
            />
          </div> -->

          <!-- <div class="mb-xs">
            <label class="form-label">code</label>
            <input formControlName="code" class="form-control" type="text" />
          </div> -->

          <!--     [disabled]="form.invalid" -->
          <!--
               buttonLoader
            [loadingState]="loading"
           -->
          <button
            type="button"
            class="btn btn-primary me-xs pe-md ps-md"
            [disabled]="form.invalid"
            (click)="submit()"
          >
            {{ "shared.save" | translate }}
          </button>
          <button type="button" class="btn btn-link" (click)="close()">
            {{ "shared.cancel" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal-backdrop fade show" *ngIf="show" (click)="close()"></div>
