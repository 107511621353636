import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Router
} from "@angular/router";
import { LocalStorageService } from "@core/services/local-storage/local-storage.service";

@Injectable({
  providedIn: "root"
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  // token: any;
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIfCanActivate(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIfCanActivate(state);
  }

  checkIfCanActivate(state: any) {
    if (localStorage.getItem("AUTH_TOKEN")) {
      return true;
    } else {
      localStorage.setItem("redirectTo", this.router.url);
      this.router.navigate(["/auth"]);
      return false;
    }
  }

  // validaetToken() {
  //   // this.token = undefined;
  //   this.userService.validateLoginToken().subscribe((res) => {
  //     this.token = true
  //   }, err => {
  //     localStorage.clear();
  //     this.token = false
  //   } );
  //   return this.token
  // }
}
