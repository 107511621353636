import { Injectable } from "@angular/core";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { Category, SubCategory } from "@shared/models/Shared.interfaces";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { GetSubcategoriesByCategoryIdInput } from "@shared/services/product-list/product-list.model";
import { CategoryApiService } from "./category.api";

@Injectable({
  providedIn: "root"
})
export class CategoryFacade {
  private _allCategoriesSubject: Subject<Category[]> = new Subject();

  constructor(private readonly categoryApiService: CategoryApiService) {}

  public loadMainApis(): void {
    this.setAllCategories();
  }
  private setAllCategories(): void {
    const variables = {
      paginationOptions: {
        page: 1,
        limit: 8
      }
    };
    this.categoryApiService
      .getAllCategories(variables)
      .subscribe((res) => this._allCategoriesSubject.next(res.data.categories));
  }

  public getAllCategories$(): Observable<Category[]> {
    return this._allCategoriesSubject.asObservable();
  }

  public getCategoriesOfFilter(): Observable<Category[]> {
    const variables = {
      paginationOptions: {
        page: 1,
        limit: 0
      }
    };
    return this.categoryApiService
      .getAllCategories(variables)
      .pipe(map((x) => x.data.categories));
  }

  public getSubCategoriesByCategoryId$(
    input: GetSubcategoriesByCategoryIdInput
  ): Observable<SubCategory[]> {
    return this.categoryApiService
      .getSubCategoryByCategoryId(input)
      .pipe(map((res) => res.data.subCategoryByCategoryId));
  }
  public getPopularCategories$(
    input: PaginationOptions
  ): Observable<Category[]> {
    return this.categoryApiService
      .getPopularCategories(input)
      .pipe(map((res) => res.data.popularCategories));
  }
}
